<div>
  <h2>{{ 'Settings::Global' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="settingsForm" (ngSubmit)="onSubmitSettings()">
  <div class="form-group pt-2">
    <label for="systemDate">{{ 'Settings::Global.SystemStartDate' | abpLocalization }}</label>
    <input
      class="form-control form-control-sm"
      type="date"
      [readonly]="true"
      formControlName="systemStartDate"
      [ngModel]="systemStartDate | date : 'yyyy-MM-dd'"
      (ngModelChange)="systemStartDate = $event" />
  </div>
  <div class="form-group pt-2">
    <div class="form-group">
      <label for="systemLanguageCode">
        {{ 'Settings::Global.SystemLanguageCode' | abpLocalization }}
      </label>
      <ca-select
        [class]="'below-all'"
        formControlName="systemLangCode"
        [(ngModel)]="systemLangCode">
        <ca-option *ngFor="let language of languages" [value]="language.code">
          {{ 'Settings::Call.Language.' + language.name | abpLocalization }}
        </ca-option>
      </ca-select>
      <small class="form-text text-muted">
        {{ 'Settings::SystemLanguageCodeDescription' | abpLocalization }}
      </small>
    </div>
  </div>
  <div class="form-group pt-2 selector-class">
    <label for="otherLangCodes">
      {{ 'Settings::InitialSetup.OtherLanguagesLabel' | abpLocalization }}
    </label>
    <ng-select
      formControlName="otherLangCodes"
      id="otherLangCodes"
      [items]="otherLanguagesDataSource"
      bindLabel="name"
      bindValue="code"
      [multiple]="true"
      [closeOnSelect]="false">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input
          id="item-{{ index }}"
          type="checkbox"
          [disabled]="isDisabled(item.code)"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }" />
        {{ 'Settings::Call.Language.' + item.name | abpLocalization }}
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <span
          class="ng-value-icon left"
          aria-hidden="true"
          (click)="removeFromSelectedOtherLanguages(item.code)">
          ×
        </span>
        <span class="ng-value-label">
          {{ 'Settings::Call.Language.' + item.name | abpLocalization }}
        </span>
      </ng-template>
    </ng-select>
    <small class="form-text text-muted">
      {{ 'Settings::OtherLanguageCodeDescription' | abpLocalization }}
    </small>
  </div>
  <div class="form-group pt-2 selector-class">
    <label class="ps-1" for="mixedLanguageType">
      {{ 'Settings::Global.MixedLanguageEnabledDescription' | abpLocalization }}
    </label>
    <ng-select
      class="select-language"
      id="mixedLanguageType"
      formControlName="mixedLanguageType"
      [clearable]="false"
      [searchable]="false"
      [(ngModel)]="mixedLanguageType">
      <ng-option
        *ngFor="let mixedLanguageType of mixedLanguageTypes"
        [value]="mixedLanguageType.code">
        {{ 'GenericLookup::' + mixedLanguageType.code | abpLocalization }}

        <ng-container
          *ngIf="
            mixedLanguageType.code != 'Conversation.MixedLanguageType.LanguageDetection' &&
            mixedLanguageType.code != 'Conversation.MixedLanguageType.SystemLanguage'
          ">
          <span style="font-size: 10px !important">
            <br />
            {{ 'Settings::Call.Language.' + mixedLanguageType.name | abpLocalization }}
          </span>
        </ng-container>

        <ng-container
          *ngIf="mixedLanguageType.code == 'Conversation.MixedLanguageType.SystemLanguage'">
          <span style="font-size: 10px !important">
            <br />
            {{ 'Settings::Call.Language.' + getCurrentSystemLanguage | abpLocalization }}
          </span>
        </ng-container>
      </ng-option>
    </ng-select>
  </div>
  <div class="form-group pt-2">
    <label for="inbound">{{ 'Settings::InboundAgentChannelDescription' | abpLocalization }}</label>
    <ca-select
      [class]="'below-all'"
      formControlName="inboundAgentChannel"
      [(ngModel)]="inboundAgentChannel">
      <ca-option *ngFor="let channel of channelTypes" [(value)]="channel.code">
        {{ 'GenericLookup::' + channel.code | abpLocalization }}
      </ca-option>
    </ca-select>
  </div>
  <div class="form-group pt-2">
    <label for="outbound">
      {{ 'Settings::OutboundAgentChannelDescription' | abpLocalization }}
    </label>
    <ca-select
      [class]="'below-all'"
      formControlName="outboundAgentChannel"
      [(ngModel)]="outboundAgentChannel">
      <ca-option *ngFor="let channel of channelTypes" [value]="channel.code">
        {{ 'GenericLookup::' + channel.code | abpLocalization }}
      </ca-option>
    </ca-select>
  </div>
  <div class="form-group pt-2">
    <label for="domainName">{{ 'Settings::DomainName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="domainName"
      formControlName="domainName"
      [(ngModel)]="domainName" />
  </div>
  <div class="form-group pt-2">
    <label for="ldapCertificateName">{{ 'Settings::CertificateName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="ldapCertificateName"
      formControlName="ldapCertificateName"
      [(ngModel)]="ldapCertificateName" />
  </div>
  <div *ngIf="analyticsFeatureEnabled" class="form-group pt-2" [hidden]="true">
    <label for="maximumAnalysisTime">
      {{ 'Settings::MaximumAnalysisTimeDescription' | abpLocalization }}
    </label>
    <input
      type="number"
      min="0"
      class="form-control form-control-sm"
      id="maximumAnalysisTime"
      formControlName="maxAnalysisTime"
      [(ngModel)]="maximumAnalysisTime" />
  </div>
  <div class="form-group pt-2">
    <label for="systemDateFormat">
      {{ 'Settings::Global.SystemDateFormat' | abpLocalization }}
    </label>
    <ca-select
      [class]="'below-all'"
      formControlName="systemDateFormat"
      [(ngModel)]="systemDateFormat">
      <ca-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat">
        {{ dateFormat }}
      </ca-option>
    </ca-select>
  </div>
  <div class="form-group pt-2">
    <label for="systemTimeZone">{{ 'Settings::Global.SystemTimeZone' | abpLocalization }}</label>
    <ca-select [class]="'below-all'" formControlName="systemTimeZone" [(ngModel)]="systemTimeZone">
      <ca-option
        *ngFor="let timeZone of timeZones | keyvalue : originalOrder"
        [(value)]="timeZone.key">
        {{ timeZone.value }}
      </ca-option>
    </ca-select>
  </div>
  <div *ngIf="userFeedbackFeatureEnabled" class="form-group pt-2">
    <label for="userFeedbackReceiverEmail">
      {{ 'Settings::UserFeedbackReceiverEmail' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="userFeedbackReceiverEmail"
      formControlName="userFeedbackReceiverEmail"
      [(ngModel)]="userFeedbackReceiverEmail" />
  </div>
  <div class="form-group pt-2">
    <label for="testEmailAddress">
      {{ 'Settings::Global.EmailAddressForTesting' | abpLocalization }}
    </label>
    <div class="input-group">
      <input
        type="text"
        class="form-control form-control-sm"
        id="testEmailAddress"
        formControlName="testEmailAddress"
        [(ngModel)]="testEmailAddress"
        placeHolder="test@example.com" />
      <button type="button" (click)="sendTestEmail()" class="btn btn-light email-test-button">
        <i class="me-1 fa fa-envelope"></i>
        {{ 'Settings::Global.SendTestEmail' | abpLocalization }}
      </button>
    </div>
  </div>

  <div class="form-group pt-2">
    <input
      type="checkbox"
      id="idleTimeoutEnabled"
      formControlName="idleTimeoutEnabled"
      [(ngModel)]="idleTimeoutEnabled" />
    <label class="ps-1" for="idleTimeoutEnabled">
      {{ 'Settings::Global.IdleTimeoutEnabledDescription' | abpLocalization }}
    </label>
  </div>

  <div class="form-group pt-2" *ngIf="idleTimeoutEnabled">
    <label for="idleTimeoutMinute">
      {{ 'Settings::IdleTimeoutMinuteDescription' | abpLocalization }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="idleTimeoutMinute"
      formControlName="idleTimeoutMinute"
      [(ngModel)]="idleTimeoutMinute" />
  </div>

  <div class="form-group pt-2">
    <input
      type="checkbox"
      id="autoPlayEnabled"
      formControlName="autoPlayEnabled"
      [(ngModel)]="autoPlayEnabled" />
    <label class="ps-1" for="autoPlayEnabled">
      {{ 'Settings::Global.AutoPlayEnabledDescription' | abpLocalization }}
    </label>
  </div>

  <div class="form-group pt-2" *ngIf="isSuperAdminUser">
    <label for="corsOriginForEmbeddedAccess">
      {{ 'Settings::CorsOriginForEmbeddedAccessDisplayName' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="corsOriginForEmbeddedAccess"
      formControlName="corsOriginForEmbeddedAccess"
      [(ngModel)]="corsOriginForEmbeddedAccess" />
    <small>{{ 'Settings::CorsOriginForEmbeddedAccessDescription' | abpLocalization }}</small>
  </div>

  <div class="form-group pt-2">
    <label for="fullTimeEquivalent">
      {{ 'Settings::FullTimeEquivalent' | abpLocalization }}
    </label>
    <div class="d-flex align-items-center">
      <input
        type="number"
        min="0"
        class="form-control form-control-sm"
        id="fullTimeEquivalent"
        formControlName="fullTimeEquivalent"
        [(ngModel)]="fullTimeEquivalent"
        style="flex-basis: 10%" />
      <span class="ms-2">
        {{ 'Settings::HoursPerMount' | abpLocalization }}
      </span>
    </div>
    <small>{{ 'Settings::FullTimeEquivalentDescription' | abpLocalization }}</small>
  </div>

  <div class="form-group pt-2">
    <input
      type="checkbox"
      id="isChangeExternalIdEnabled"
      formControlName="isChangeExternalIdEnabled"
      [(ngModel)]="isChangeExternalIdEnabled" />
    <label class="ps-1" for="isChangeExternalIdEnabled">
      {{ 'Settings::Global.IsChangeExternalIdEnabled' | abpLocalization }}
    </label>
  </div>

  <button type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
