import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, take } from 'rxjs';
import { RoleHelper } from 'src/core/helpers/role-helper';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { LicenseSettingsDto } from 'src/core/models/license-setting/license-settings.dto';
import { LicensingFeature } from 'src/core/models/license-setting/licensing-feature-enum';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { LicenseSettingsService } from 'src/core/services/license-settings/license-settings.service';

@Component({
  selector: 'ca-licensing-settings',
  templateUrl: './licensing-settings.component.html',
  styleUrls: ['./licensing-settings.component.scss'],
})
export class LicensingSettingsComponent implements OnInit {
  @ViewChild('licenseFeaturesModal')
  licenseFeaturesModal: any;

  form: FormGroup;
  isSuperAdminUser: boolean = false;
  processing = false;
  featureList: string[] = null;

  private readonly prefix = 'License';
  private readonly settingKeyExcludeImportAgent = this.prefix + '.ExcludeImportAgent';

  get regexUri(): RegExp {
    return /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private roleHelper: RoleHelper,
    private licenseService: LicenseSettingsService,
    private modalService: NgbModal,
    private licensingFeature: LicensingFeature,
    private localizationService: LocalizationService
  ) {
    this.form = fb.group({
      clientId: [
        null,
        {
          updateOn: 'change',
        },
      ],
      clientSecret: [
        null,
        {
          updateOn: 'change',
        },
      ],
      licenseURL: [
        null,
        {
          validators: [Validators.pattern(this.regexUri)],
          updateOn: 'change',
        },
      ],
      excludeImportAgent: [
        JSON.parse(this.config.getSetting(this.settingKeyExcludeImportAgent).toLowerCase()),
      ],
    });

    this.form
      .get('excludeImportAgent')
      .patchValue(
        JSON.parse(this.config.getSetting(this.settingKeyExcludeImportAgent).toLowerCase())
      );

    this.isSuperAdminUser = this.roleHelper.isSuperAdminUser();
  }

  ngOnInit(): void {}

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.settingKeyExcludeImportAgent,
      settingValue: this.form.get('excludeImportAgent').value.toString(),
    });

    var request = this.settingService.saveSetting(settings);

    if (this.isLicenseSettingsSet()) {
      this.processing = true;
      combineLatest([request, this.licenseService.saveSettings(this.getLicenseObject())])
        .pipe(take(1))
        .subscribe({
          next: ([settingsData, featureData]) => {
            this.featureList = [];
            featureData.forEach(data => {
              this.featureList.push(this.getFeatureName(data));
            });
            this.processing = false;
            this.toastr.success(
              this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
            );
            this.modalService.open(this.licenseFeaturesModal, {
              size: 'md',
              scrollable: false,
              backdrop: 'static',
              keyboard: false,
            });
          },
          error: () => {
            this.processing = false;
          },
        });
    } else {
      request.subscribe(() => {
        this.toastr.success(
          this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
        );
      });
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  private isLicenseSettingsSet(): boolean {
    return (
      this.form.get('clientId').value &&
      this.form.get('clientSecret').value &&
      this.form.get('licenseURL').value
    );
  }

  private getLicenseObject(): LicenseSettingsDto {
    let licenseSettings = new LicenseSettingsDto();
    licenseSettings.clientId = this.form.get('clientId').value;
    licenseSettings.clientSecret = this.form.get('clientSecret').value;
    licenseSettings.licenseURL = this.form.get('licenseURL').value;
    return licenseSettings;
  }

  private getFeatureName(value): string {
    for (const key in this.licensingFeature) {
      if (this.licensingFeature[key] == value) {
        return key;
      }
    }
  }
}
