import { ServerSideEnum } from '../enum/server-side-enum.model';
import { Injectable, Injector } from '@angular/core';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('LicensingFeature')
export class LicensingFeature extends ServerSideEnum {
  CA_Agent: number;
  CA_Chat: number;
  CA_Analytics: number;
  CA_AQM: number;
  CA_Realtime: number;
  CA_Screen_Recorder: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
