import { RoutesService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavMenuItemService {
  private _customNavMenuItems: any[] = [
    { name: 'LanguageManagement::Languages', iconClass: 'far fa-globe' },
    { name: 'LanguageManagement::LanguageTexts', iconClass: 'far fa-language' },
    { name: 'AbpIdentity::Users', iconClass: 'far fa-users' },
    { name: 'AbpIdentity::SecurityLogs', iconClass: 'far fa-lock-alt' },
    { name: 'AbpIdentity::OrganizationUnits', iconClass: 'far fa-sitemap' },
    { name: 'AbpIdentity::Roles', iconClass: 'far fa-user-tag' },
    { name: 'AbpUiNavigation::Menu:Administration', iconClass: 'far fa-wrench' },
    { name: 'LanguageManagement::LanguageManagement', iconClass: 'far fa-globe', order: 5 },
    { name: 'AbpAuditLogging::Menu:AuditLogging', iconClass: 'far fa-file-text', order: 7 },
    { name: 'AbpSettingManagement::Settings', iconClass: 'far fa-cog', order: 9 },
    { name: 'TextTemplateManagement::Menu:TextTemplates', iconClass: 'far fa-envelope-open-text', order: 6 },
    { name: 'AbpIdentity::Menu:IdentityManagement', iconClass: 'fal fa-id-card', order: 3 },
    { name: 'Saas::Menu:Saas', iconClass: 'far fa-users' },
    { name: 'AbpIdentityServer::Menu:IdentityServer', iconClass: 'fal fa-id-card' },
  ];

  private _premiumReportsNavMenuItems: any[] = [
    { name: 'PremiumReporting::Menu:PremiumReports', iconClass: 'far fa-file-chart-pie' },
  ];

  constructor(private routes: RoutesService) {}

  overrideNavMenuItemIcons() {
    this._customNavMenuItems.forEach(menuItem => {
      let itemElement = this.routes.flat.find(x => x.name == menuItem.name);
      if (itemElement) {
        itemElement.iconClass = menuItem.iconClass;
        if(menuItem.order){
          itemElement.order = menuItem.order;
        }
        this.routes.patch(itemElement.name, itemElement);
      }
    });
  }

  overridePremiumReportsNavMenuItemIcons() {
    setTimeout(() => {
      this._premiumReportsNavMenuItems.forEach(menuItem => {
        let itemElement = this.routes.flat.find(x => x.name == menuItem.name);
        this.routes.patch(itemElement?.name, {
          iconClass: menuItem.iconClass,
        });
      });
    }, 500);
  }
}
