import { ServerSideEnum } from '../enum/server-side-enum.model';
import { Injectable, Injector } from '@angular/core';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

// tslint:disable: variable-name
// enums should be in PascalCase

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('ConversationFilterableFields')
export class ConversationFilterableFields extends ServerSideEnum {
  // Conversation
  Conversation_Type: number;
  Conversation_Time: number;
  Conversation_Duration: number;
  Conversation_ExternalId: number;
  Conversation_Agent: number;
  Conversation_DepartmentId: number;
  Conversation_TagId: number;
  Conversation_RatingForNPS: number;
  Conversation_CustomerSentiment: number;
  Conversation_SentimentTrend: number;
  Conversation_AgreementStatus: number;
  Conversation_AgentSentiment: number;
  Conversation_NegativeContent: number;
  Conversation_NerEntity: number;
  Conversation_NerWithValueEntity: number;
  Conversation_NerWithRangeEntity: number;
  Conversation_GenAIYesNoEntity: number;
  Conversation_GenAIYesNoWithRangeEntity: number;
  Conversation_HasScreenRecording: number;
  Conversation_RatingForCSAT: number;

  // Call
  Call_CallerNumber: number;
  Call_CalledNumber: number;
  Call_Direction: number;
  Call_NonFcrStatus: number;
  Call_HoldDuration: number;
  Call_ReleasingParty: number;

  // Call Analysis
  CallAnalysis_AgentSpeed: number;
  CallAnalysis_AgentTensionRatio: number;
  CallAnalysis_OverlapDuration: number;
  CallAnalysis_OverlapRatio: number;
  CallAnalysis_MaxOverlapDuration: number;
  CallAnalysis_MaxSilenceDuration: number;
  CallAnalysis_SilenceRatio: number;
  CallAnalysis_AgentBlockRatio: number;
  CallAnalysis_CustomerBlockRatio: number;
  CallAnalysis_CustomerGender: number;
  CallAnalysis_AgentMonotonocityRatio: number;
  CallAnalysis_CustomerTensionRatio: number;
  CallAnalysis_CallSilenceDurationBeginning: number;
  CallAnalysis_CallSilenceDurationEnd: number;
  CallAnalysis_AgentInterruptionCountPerMinute: number;
  CallAnalysis_CustomerShare: number;
  CallAnalysis_AgentHesitationCountPerMinute: number;

  // Chat
  Chat_Title: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
