import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-basic-notification',
  templateUrl: './basic-notification.component.html',
  styleUrl: './basic-notification.component.scss',
})
@NotificationType('BasicNotification', BasicNotificationComponent)
export class BasicNotificationComponent {
  data: NotificationDto;

  get categoryNames(): string {
    return this.data ? JSON.parse(this.data.payload).categoryNames : '';
  }

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const configStateService = injector.get(ConfigStateService);

    configStateService.refreshAppState().subscribe();

    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(parsedPayload.message);

    toastr.info(message);
  }
}
