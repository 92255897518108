import { Component, OnInit } from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';
import { ConversationDetailService } from 'src/core/services/conversation/conversation-detail.service';

@Component({
  selector: 'ca-conversation-automatic-evaluation-complete-action-log',
  templateUrl: './conversation-automatic-evaluation-complete-action-log.component.html',
  styleUrls: ['./conversation-automatic-evaluation-complete-action-log.component.scss'],
})
export class ConversationAutomaticEvaluationCompleteActionLogComponent implements OnInit {
  data: ActionLogDto;
  activeTab: number;
  private _payloadData: any;

  get payloadData(): any {
    if (!this._payloadData) {
      this._payloadData = JSON.parse(this.data?.payload);
    }
    return this._payloadData;
  }

  constructor(private conversationDetailService: ConversationDetailService
  ) { }

  onClickEvaluationResult(evaluationId, evaluationMasterId): void {
    this.conversationDetailService.showEvaluationResult(
      evaluationId,
      evaluationMasterId
    );
  }

  ngOnInit(): void { }
}
