import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { first, map, mergeMap } from 'rxjs/operators';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Operators } from 'src/core/models/request/operator.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { QueryConstants } from 'src/core/constants/query-constant';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ConversationNerQueryItemDto } from 'src/core/models/query/conversation-ner-query-item.dto';
import { ConversationNerWithRangeQueryItemDto } from 'src/core/models/query/conversation-ner-with-range-query-item.dto';
import { ConversationNerWithValueQueryItemDto } from 'src/core/models/query/conversation-ner-with-value-query-item.dto';
import { PhraseTypeSettingsService } from 'src/core/services/settings/phrase-type-settings.service';
import { NERTemplateDto } from 'src/core/models/conversation/phrase-type/ner-template.dto';

export enum EntityQueryType {
  Basic = 1,
  Value,
  Interval,
}

export enum EntityQueryIntervalType {
  Opening = '1',
  Closing = '2',
}
@Component({
  selector: 'ca-query-tree-node-conversation-ner',
  templateUrl: './query-tree-node-conversation-ner.component.html',
  styleUrls: ['./query-tree-node-conversation-ner.component.scss'],
})
export class QueryTreeNodeConversationNerComponent extends QueryEditorBase implements OnInit {
  payload: any;
  conversationSides$: Observable<GenericLookupDto[]>;
  rangeUnits$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;
  conversationSideAny: number = ConversationSide.any;
  templateList: NERTemplateDto[] = [];

  EntityQueryType = EntityQueryType;
  EntityQueryIntervalType = EntityQueryIntervalType;
  private currentEntityQueryTypeInternal: EntityQueryType = EntityQueryType.Basic;
  private currentEntityQueryIntervalTypeInternal = EntityQueryIntervalType.Opening;
  readonly RANGE_SECONDS = QueryConstants.RANGE_OPENING_END_SECOND.toString();

  rangeUnitSecondsId: number;
  selectedNerId: number = 0;

  formOperator: number;
  formIsNotQuery: boolean;
  formSideId: number;
  formRangeUnitId: number;
  formStartTime: number;
  formEndTime: number;

  inputPhrases$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  conversationType = ConversationType;

  intervalAgentQueryFirstNWords: string = '';
  intervalAgentQueryLastNWords: string = '';
  intervalCustomerQueryFirstNWords: string = '';
  intervalCustomerQueryLastNWords: string = '';
  seperateIntervalValueForChannelsEnabled: boolean = false;

  operatorList = [
    {
      notation: this.operators.Contains,
      label: "Conversation::Exists",
      icon: null,
    },
    {
      notation: this.operators.NotContains,
      label: "Conversation::DoesNotExist",
      icon: null,
    },
  ];

  get currentEntityQueryType(): EntityQueryType {
    return this.currentEntityQueryTypeInternal;
  }

  get currentEntityQueryIntervalType(): EntityQueryIntervalType {
    return this.currentEntityQueryIntervalTypeInternal;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private store: Store,
    public operators: Operators,
    private queryFieldDataType: QueryFieldDataType,
    private genericLookupService: GenericLookupTypeService,
    private localizationService: LocalizationService,
    private phraseTypeSettingsService: PhraseTypeSettingsService,
    private config: ConfigStateService
  ) {
    super();

    this.intervalAgentQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.AgentBeginningWordCount'
    );
    this.intervalAgentQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.AgentEndingWordCount'
    );
    this.intervalCustomerQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerBeginningWordCount'
    );
    this.intervalCustomerQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerEndingWordCount'
    );
    this.seperateIntervalValueForChannelsEnabled = JSON.parse(this.config.getSetting('UserDefinedCategory.SeperateValueForChannelsEnabled').toLowerCase());

    this.conversationSideAny = ConversationSide.any;

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$
      .pipe(
        first(),
        mergeMap(x => x.filter(y => y.code == QueryConstants.RANGE_UNIT_SECONDS_CODE))
      )
      .subscribe(data => {
        this.rangeUnitSecondsId = data.id;
      });

    this.queryForm = fb.group(
      {
        term: [null],
        nerId: [null, Validators.required],
        conversationSide: [null],
        operator: [this.operators.Contains],
        intervalType: [EntityQueryIntervalType.Opening],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('term').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.formSideId = this.queryForm.get('conversationSide').value;
      this.payload.sideId = this.formSideId;
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });

    this.queryForm.get('intervalType').valueChanges.subscribe(value => {
      const intervalType: EntityQueryIntervalType = this.queryForm.get('intervalType').value;
      this.formRangeUnitId = this.rangeUnitSecondsId;
      this.payload.rangeUnitId = this.formRangeUnitId;

      if (intervalType === EntityQueryIntervalType.Opening) {
        this.formStartTime = QueryConstants.RANGE_OPENING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_OPENING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else {
        this.formStartTime = QueryConstants.RANGE_CLOSING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_CLOSING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      }
    });
  }

  templateSelectionChanged(event): void {
    this.selectedNerId = event.target.value;
  }

  get selectedDefination(): NERTemplateDto {
    if (this.selectedNerId != null && this.selectedNerId > 0) {
      return this.templateList.find(a => a.id == this.selectedNerId);
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as ConversationNerQueryItemDto;
    let dtoType = this.node.categoryItem.dataType;

    this.formOperator = this.payload.operator;
    this.formSideId = this.payload.sideId;

    this.phraseTypeSettingsService
      .getNERTemplates()
      .pipe(take(1))
      .subscribe({
        next: data => {
          if (this.payload.nerId > 0 && data !== undefined && data.length > 0) {
            this.templateList = data.filter(
              item => item.id === this.payload.nerId || item.isEnabled
            );
          } else if (
            (this.payload.nerId === 0 || !this.payload.nerId) &&
            data !== undefined &&
            data.length > 0
          ) {
            this.templateList = data.filter(item => item.isEnabled);
            // set default select option
            this.payload.nerId = this.templateList[0].id;
            this.selectedNerId = this.payload.nerId;
          }

          if (this.payload.nerId != null && this.payload.nerId != 0 && data) {
            //this.currentdefinitionName = data.items.find(x => x.id == this.payload.nerId).name;
            this.selectedNerId = this.payload.nerId;
          }

          this.node.validationStatus = this.queryForm.valid;
        },
        error: () => { },
      });

    this.queryForm.get('nerId').patchValue(this.payload.nerId);
    this.queryForm.get('conversationSide').patchValue(this.payload.sideId);

    switch (dtoType) {
      case this.queryFieldDataType.ConversationNer:
        this.currentEntityQueryTypeInternal = EntityQueryType.Basic;
        this.changeQueryType(EntityQueryType.Basic, true);
        break;
      case this.queryFieldDataType.ConversationNerWithValue:
        this.currentEntityQueryTypeInternal = EntityQueryType.Value;
        this.queryForm.get('term').patchValue(this.payload.term);
        this.changeQueryType(EntityQueryType.Value, true);
        break;
      case this.queryFieldDataType.ConversationNerWithRange:
        this.currentEntityQueryTypeInternal = EntityQueryType.Interval;
        this.queryForm.get('term').patchValue(this.payload.term);

        if (
          this.payload.startTime === QueryConstants.RANGE_OPENING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_OPENING_END_SECOND
        ) {
          this.currentEntityQueryIntervalTypeInternal = EntityQueryIntervalType.Opening;
          this.queryForm.get('intervalType').patchValue(EntityQueryIntervalType.Opening);
        } else if (
          this.payload.startTime === QueryConstants.RANGE_CLOSING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_CLOSING_END_SECOND
        ) {
          this.currentEntityQueryIntervalTypeInternal = EntityQueryIntervalType.Closing;
          this.queryForm.get('intervalType').patchValue(EntityQueryIntervalType.Closing);
        }
        this.changeQueryType(EntityQueryType.Interval, true);
        break;
    }

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      let conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
    this.updateInputPhrases();
  }

  onQueryTypeMenuItemClicked(queryType: EntityQueryType) {
    this.changeQueryType(queryType, false);
  }

  getSiblingNodePhrases(): string[] {
    return this.queryBuilder.getSiblingNodePhrasesOfNode(this.node);
  }

  updateInputPhrases() {
    this.inputPhrases$.next(this.getSiblingNodePhrases());
  }

  private changeQueryType(queryType: EntityQueryType, initial: boolean) {
    this.currentEntityQueryTypeInternal = queryType;
    let sideCode = initial
      ? this.genericLookupService.findGenericLookupWithId(this.payload.sideId)?.code
      : this.payload.sideCode;
    switch (queryType) {
      case EntityQueryType.Basic:
        let payloadSimple = new ConversationNerQueryItemDto();

        payloadSimple.categoryId = this.payload.categoryId;
        payloadSimple.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_NER;
        payloadSimple.id = initial ? this.payload.id : 0;
        payloadSimple.internalId = this.payload.internalId;
        payloadSimple.operator = this.payload.operator;
        payloadSimple.parentInternalId = this.payload.parentInternalId;
        payloadSimple.queryId = this.payload.queryId;

        payloadSimple.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadSimple.sideCode = sideCode;
        payloadSimple.field = this.payload.field;
        payloadSimple.nerId = this.payload.nerId;

        this.payload = payloadSimple;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.ConversationNer;
        break;
      case EntityQueryType.Interval:
        if (initial == false) {
          this.queryForm.get('intervalType').patchValue(EntityQueryIntervalType.Opening);
        }
        let payloadInterval = new ConversationNerWithRangeQueryItemDto();

        payloadInterval.categoryId = this.payload.categoryId;
        payloadInterval.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_NER_RANGE;
        payloadInterval.id = initial ? this.payload.id : 0;
        payloadInterval.internalId = this.payload.internalId;
        payloadInterval.operator = this.payload.operator;
        payloadInterval.parentInternalId = this.payload.parentInternalId;
        payloadInterval.queryId = this.payload.queryId;

        payloadInterval.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadInterval.sideCode = sideCode;
        payloadInterval.term = this.payload.term;

        payloadInterval.startTime = initial ? this.payload.startTime : this.formStartTime;
        payloadInterval.endTime = initial ? this.payload.endTime : this.formEndTime;
        payloadInterval.rangeUnitId = this.formRangeUnitId;
        payloadInterval.field = this.payload.field;
        payloadInterval.nerId = this.payload.nerId;

        this.payload = payloadInterval;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.ConversationNerWithRange;
        break;
      case EntityQueryType.Value:
        let payloadValue = new ConversationNerWithValueQueryItemDto();


        payloadValue.categoryId = this.payload.categoryId;
        payloadValue.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_NER_VALUE;
        payloadValue.id = initial ? this.payload.id : 0;
        payloadValue.internalId = this.payload.internalId;
        payloadValue.operator = this.payload.operator;
        payloadValue.parentInternalId = this.payload.parentInternalId;
        payloadValue.queryId = this.payload.queryId;

        payloadValue.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadValue.sideCode = sideCode;
        payloadValue.term = this.payload.term;
        payloadValue.field = this.payload.field;
        payloadValue.nerId = this.payload.nerId;

        this.payload = payloadValue;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.ConversationNerWithValue;
        break;
    }

    if (!initial) {
      this.node.isDirty = true;
    }
  }

  getOpeningIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent || !this.seperateIntervalValueForChannelsEnabled) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryFirstNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideFirstQueryNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryFirstNWords);
    }
    return tooltip;
  }

  getClosingIntervalTooltip(): string {
    let tooltip = '';
    if (this.payload.sideId === ConversationSide.agent || !this.seperateIntervalValueForChannelsEnabled) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryLastNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryLastNWords);
    }
    return tooltip;
  }

  getOperatorLabel(operator) {
    return this.operatorList.find(x => x.notation == operator).label;
  }

  getTemplateTooltip(nerId) {
    if (this.templateList.length > 0) {
      return this.templateList.find(x => x.id == nerId).labelClass;
    }
    return;
  }
}
