<div class="row display-inline" [formGroup]="editorForm" container="body">
  <div class="col-5">
    <label
      *ngIf="actualConfig.label && actualConfig.label.length > 0"
      class="label-padding"
      [for]="'number-editor-' + this.actualConfig.key">
      {{ actualConfig.label | abpLocalization }}
    </label>
  </div>
  <div class="col no-padding-left">
    <ca-select formControlName="operator" (change)="onSelectChange($event)">
      <ca-option *ngFor="let operator of actualConfig.operators" [value]="operator">
        {{ getNotation(operator) }}
      </ca-option>
    </ca-select>
  </div>
  <div *ngIf="showValidationMessage" class="col">
    <input
      type="number"
      class="form-control form-control-sm"
      formControlName="editorValue"
      [min]="actualConfig.minValue"
      [max]="actualConfig.maxValue"
      (change)="onChangeValue($event.currentTarget.value)"
      (keydown)="handleKeydown($event)" />
  </div>
  <div *ngIf="!showValidationMessage" class="col">
    <input
      type="number"
      class="form-control form-control-sm"
      formControlName="editorValue"
      (change)="onChangeValue($event.currentTarget.value)"
      (keydown)="handleKeydown($event)" />
  </div>
</div>
