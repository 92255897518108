import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTreeModule } from '@angular/material/tree';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import { DepartmentSelectionTree } from './department/tree/department-selection-tree';
import { UserSelection } from './user/user-selection';
import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ConversationListTypeSelectorComponent } from './conversation-list-type-selector/conversation-list-type-selector/conversation-list-type-selector.component';
import { CollapsibleComponent } from './collapsible/collapsible/collapsible.component';
import { EditableLabelComponent } from './editable-label/editable-label.component';
import { ConversationDateFilterComponent } from './conversation-date-filter/conversation-date-filter.component';
import { ConversationSideSelectorComponent } from './conversation-side-selector/conversation-side-selector.component';
import { FormsModule } from '@angular/forms';
import { DatetimeRangePickerModule } from 'src/ca-shared/datetime-range-picker/datetime-range-picker.module';
import { DatetimePickerModule } from 'src/ca-shared/datetime-picker/datetime-picker.module';
import { ClickableLinkComponent } from './clickable-link/clickable-link.component';
import { ConversationAnalysisCompleteActionLogComponent } from './action-log/action-types/conversation-analysis-complete-action-log/conversation-analysis-complete-action-log.component';
import { ConversationAnalysisStartActionLogComponent } from './action-log/action-types/conversation-analysis-start-action-log/conversation-analysis-start-action-log.component';
import { ConversationAutomaticEvaluationCompleteActionLogComponent } from './action-log/action-types/conversation-automatic-evaluation-complete-action-log/conversation-automatic-evaluation-complete-action-log.component';
import { ConversationAutomaticEvaluationStartActionLogComponent } from './action-log/action-types/conversation-automatic-evaluation-start-action-log/conversation-automatic-evaluation-start-action-log.component';
import { ConversationCreateActionLogComponent } from './action-log/action-types/conversation-create-action-log/conversation-create-action-log.component';
import { ConversationIndexCompleteActionLogComponent } from './action-log/action-types/conversation-index-complete-action-log/conversation-index-complete-action-log.component';
import { ConversationIndexStartActionLogComponent } from './action-log/action-types/conversation-index-start-action-log/conversation-index-start-action-log.component';
import { ShowTooltipIfTruncatedDirective } from 'src/core/directives/show-tooltip-if-truncated.directive';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ActionLogRowComponent } from './action-log/action-log-row/action-log-row.component';
import { UserAutoCompleteSelectorComponent } from './user-auto-complete-selector/user-auto-complete-selector.component';
import { SelectorModule } from './selector/selector.module';
import { DropdownSelectorWrapperComponent } from './dropdown-selector-wrapper/dropdown-selector-wrapper.component';
import { TemplateDropdownSelectorComponent } from './template-selector/components/template-dropdown-selector/template-dropdown-selector.component';
import { DepartmentSelectorComponent } from './department-selector/department-selector.component';
import { DropdownTreeModule } from './dropdown-tree/dropdown-tree.module';
import { BlockTemplateWithMessageComponent } from './block-templates/block-template-with-message/block-template-with-message.component';
import { AutoSelectOnFocus } from 'src/core/directives/auto-select-on-focus.directive';
import { TimerComponent } from './timer/timer.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserIdentityComponent } from './user/user-identity/user-identity.component';
import { NotificationIconComponent } from './nav-items/notification-icon/notification-icon.component';
import { ResetUserSettingsComponent } from './nav-items/reset-user-settings/reset-user-settings.component';
import { AboutComponent } from './nav-items/about/about.component';
import { VersionInfoComponent } from './nav-items/version-info/version-info.component';
import { LanguagesComponent } from './nav-items/languages/languages.component';
import { VideoPlayerIconComponent } from './nav-items/video-player-icon/video-player-icon.component';
import { ConversationTextCorrectionCompleteActionLogComponent } from './action-log/action-types/conversation-text-correction-complete-action-log/conversation-text-correction-complete-action-log.component';
import { ConversationTranscriptEditedActionLogComponent } from './action-log/action-types/conversation-transcript-edited-action-log/conversation-transcript-edited-action-log.component';
import { ConversationViewedActionLogComponent } from './action-log/action-types/conversation-viewed-action-log/conversation-viewed-action-log.component';
import { FilterBreadcrumbPanelComponent } from './filter-breadcrumb/filter-breadcrumb-panel/filter-breadcrumb-panel.component';
import { FilterBreadcrumbComponent } from './filter-breadcrumb/filter-breadcrumb/filter-breadcrumb.component';
import { TimerForBreadcrumbRemovalComponent } from './filter-breadcrumb/timer-for-breadcrumb-removal/timer-for-breadcrumb-removal.component';
import { RatingModule } from './rating/rating.module';
import { CoreModule as CACoreModule } from 'src/core/core.module';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { AttachedDataMultipleSelectorComponent } from './attached-data-multiple-selector/attached-data-multiple-selector.component';
import { ClearCacheComponent } from './nav-items/clear-cache/clear-cache.component';
import { UserPhotoModule } from './user-photo/user-photo.module';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { EnableCompactModeDirective } from 'src/core/directives/enable-compact-mode.directive';
import { NotifyOnScrollToBottomDirective } from 'src/core/directives/notify-on-scroll-to-bottom.directive';
import { CANumberPipe } from 'src/core/pipes/ca-number.pipe';
import { ConversationTagAddedActionLogComponent } from './action-log/action-types/conversation-tag-added-action-log/conversation-tag-added-action-log.component';
import { ConversationTagRemovedActionLogComponent } from './action-log/action-types/conversation-tag-removed-action-log/conversation-tag-removed-action-log.component';
import { ConversationDownloadedActionLogComponent } from './action-log/action-types/conversation-downloaded-action-log/conversation-downloaded-action-log.component';
import { ConversationMarkChannelSelectorComponent } from './conversation-mark-channel-selector/conversation-mark-channel-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoScrollDirective } from 'src/core/directives/no-scroll.directive';
import { ConversationRedactedDataViewedActionLogComponent } from './action-log/action-types/conversation-redacted-data-viewed-action-log/conversation-redacted-data-viewed-action-log.component';
import { ConversationPriorForEvaluationAddedActionLogComponent } from './action-log/action-types/conversation-prior-for-evaluation-added-action-log/conversation-prior-for-evaluation-added-action-log.component';
import { ConversationNotSuitableForEvaluationAddedActionLogComponent } from './action-log/action-types/conversation-not-suitable-for-evaluation-added-action-log/conversation-not-suitable-for-evaluation-added-action-log.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { BasicNotificationComponent } from './notifications/basic-notification/basic-notification.component';

export * from './has-subscription/has-subscription';

@NgModule({
  declarations: [
    DepartmentSelectionTree,
    UserSelection,
    ConversationListTypeSelectorComponent,
    CollapsibleComponent,
    ClickableLinkComponent,
    EditableLabelComponent,
    ConversationSideSelectorComponent,
    ConversationDateFilterComponent,
    ConversationCreateActionLogComponent,
    ConversationAnalysisStartActionLogComponent,
    ConversationAnalysisCompleteActionLogComponent,
    ConversationIndexStartActionLogComponent,
    ConversationIndexCompleteActionLogComponent,
    ConversationAutomaticEvaluationStartActionLogComponent,
    ConversationAutomaticEvaluationCompleteActionLogComponent,
    ConversationViewedActionLogComponent,
    ConversationTranscriptEditedActionLogComponent,
    FilterBreadcrumbPanelComponent,
    FilterBreadcrumbComponent,
    TimerForBreadcrumbRemovalComponent,
    AttachedDataMultipleSelectorComponent,
    ActionLogRowComponent,
    UserIdentityComponent,
    SearchBoxComponent,
    ShowTooltipIfTruncatedDirective,
    EnableCompactModeDirective,
    NoScrollDirective,
    NotifyOnScrollToBottomDirective,
    UserAutoCompleteSelectorComponent,
    DepartmentSelectorComponent,
    BlockTemplateWithMessageComponent,
    DropdownSelectorWrapperComponent,
    TemplateDropdownSelectorComponent,
    AutoSelectOnFocus,
    TimerComponent,
    VideoPlayerComponent,
    NotificationIconComponent,
    ResetUserSettingsComponent,
    AboutComponent,
    VersionInfoComponent,
    LanguagesComponent,
    VideoPlayerIconComponent,
    ConversationTextCorrectionCompleteActionLogComponent,
    ClearCacheComponent,
    NotFoundPageComponent,
    ConversationTagAddedActionLogComponent,
    ConversationTagRemovedActionLogComponent,
    ConversationDownloadedActionLogComponent,
    ConversationMarkChannelSelectorComponent,
    ConversationRedactedDataViewedActionLogComponent,
    ConversationPriorForEvaluationAddedActionLogComponent,
    ConversationNotSuitableForEvaluationAddedActionLogComponent,
    BasicNotificationComponent,
  ],
  imports: [
    CoreModule,
    NgbModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    NgbDropdownModule,
    DragDropModule,
    AngularSplitModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSliderModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTreeModule,
    NgbModalModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSelectSearchModule,
    DatetimeRangePickerModule,
    DatetimePickerModule,
    FormsModule,
    SelectorModule,
    RatingModule,
    CACoreModule,
    DropdownTreeModule,
    NgbTooltipModule,
    UserPhotoModule,
    NgSelectModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    NgbDropdownModule,
    DepartmentSelectionTree,
    ConversationSideSelectorComponent,
    ConversationListTypeSelectorComponent,
    ClickableLinkComponent,
    UserSelection,
    UserIdentityComponent,
    MatSelectSearchModule,
    CollapsibleComponent,
    FilterBreadcrumbPanelComponent,
    FilterBreadcrumbComponent,
    AttachedDataMultipleSelectorComponent,
    DropdownSelectorWrapperComponent,
    TemplateDropdownSelectorComponent,
    ShowTooltipIfTruncatedDirective,
    EnableCompactModeDirective,
    NoScrollDirective,
    NotifyOnScrollToBottomDirective,
    EditableLabelComponent,
    ActionLogRowComponent,
    ConversationDateFilterComponent,
    BlockTemplateWithMessageComponent,
    SearchBoxComponent,
    UserAutoCompleteSelectorComponent,
    DepartmentSelectorComponent,
    AutoSelectOnFocus,
    TimerComponent,
    VideoPlayerComponent,
    ConversationMarkChannelSelectorComponent,
  ],
  providers: [CATimePipe, CANumberPipe, CADatePipe],
  bootstrap: [BasicNotificationComponent],
})
export class CaSharedModule {}
