import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { QueryService } from 'src/core/services/query/query.service';
import { QueryTemporaryType } from 'src/core/models/enum/query-temporary-type.enum';

@Component({
  selector: 'ca-query-index-nearnot-completed-notification',
  templateUrl: './query-index-nearnot-completed-notification.component.html',
  styleUrls: ['./query-index-nearnot-completed-notification.component.scss'],
})
@NotificationType(
  'QueryIndexNearOrNotCompletedNotification',
  QueryIndexNearOrNotCompletedNotificationComponent
)
export class QueryIndexNearOrNotCompletedNotificationComponent implements OnInit {
  data: NotificationDto;

  get phrases(): string {
    return this.data ? JSON.parse(this.data.payload).phrases : '';
  }

  get temporaryType(): number {
    return this.data ? JSON.parse(this.data.payload).temporaryType : '';
  }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private service: QueryService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private queryTemporaryType: QueryTemporaryType
  ) {}

  ngOnInit(): void {}

  navigate() {
    const parsedPayload = JSON.parse(this.data.payload);

    this.service.existTempQuery(parsedPayload.queryId).subscribe(response => {
      if (response == true) {
        if (
          this.temporaryType == this.queryTemporaryType.Conversation ||
          this.temporaryType == null
        ) {
          this.router.navigate(['/conversation'], {
            queryParams: {
              queryId: parsedPayload.queryId,
              isTempQuery: true,
            },
          });
        } else {
          this.router.navigate(['/analysis-report'], {
            queryParams: {
              queryId: parsedPayload.queryId,
              isTempQuery: true,
            },
          });
        }
      } else {
        this.toastr.warn(
          this.localizationService.instant('Query::ExistTempQueryWarningMessage', this.phrases)
        );
      }
    });

    this.notificationService.getReadStatus(this.data.id).subscribe(result => {
      if (!result) {
        this.notificationService.markAsRead(this.data.id).subscribe();
      }
    });
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Query::IndexingNearNotCompletedNotification',
      parsedPayload.phrases
    );

    const toastrId = toastr.success(message, null, {
      sticky: true,
    });

    const toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        injector,
        notificationService,
        notificationId,
        parsedPayload.queryId,
        parsedPayload.temporaryType,
        localizationService,
        router
      );
    });
  }

  static toasterClickedHandler(
    injector: Injector,
    notificationService: NotificationService,
    notificationId: string,
    queryId: string,
    temporaryType: number,
    localizationService: LocalizationService,
    router: Router
  ) {
    const queryTemporaryType = injector.get(QueryTemporaryType);

    if (temporaryType == queryTemporaryType.Conversation || temporaryType == null) {
      router.navigate(['/conversation'], {
        queryParams: {
          queryId: queryId,
          isTempQuery: true,
        },
      });
    } else {
      router.navigate(['/analysis-report'], {
        queryParams: {
          queryId: queryId,
          isTempQuery: true,
        },
      });
    }

    notificationService.markAsRead(notificationId).subscribe();
  }
}
