import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { AdvancedOrderedGroupQueryItemDto } from 'src/core/models/query/advanced-ordered-group-query-item.dto';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ca-query-tree-node-advanced-ordered-group',
  templateUrl: './query-tree-node-advanced-ordered-group.component.html',
  styleUrls: ['./query-tree-node-advanced-ordered-group.component.scss'],
})
export class QueryTreeNodeAdvancedOrderedGroupComponent extends QueryEditorBase implements OnInit {
  payload: AdvancedOrderedGroupQueryItemDto;
  queryForm: FormGroup = null;
  categoryItems: IQueryBuilderCategoryItem[] = [];

  private categories: QueryBuilderCategoryDto[];

  onMenuOpened() {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach(btn => {
      btn.classList.add('show-on-hover-disabled');
    });
  }

  onMenuClosed() {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach((btn: HTMLElement) => {
      btn.classList.remove('show-on-hover-disabled');
      btn.style.display = 'none';
    });
  }

  onAddNodeClick(category: QueryBuilderCategoryDto, categoryItem: IQueryBuilderCategoryItem) {
    this.queryBuilder.addNodeFromAnotherNode(this.node, this.node.category, categoryItem);
  }

  constructor(
    private fb: FormBuilder,
    private viewContainerRef: ViewContainerRef,
    public queryField: ConversationFilterableFields,
    public queryFieldDataType: QueryFieldDataType
  ) {
    super();

    this.queryForm = fb.group(
      {
        conversationSide: [null],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.categories
      ?.map(c => c.items)
      .reduce(
        (acc, cur) => (
          (this.categoryItems = this.categoryItems.concat(
            acc
              ? acc
                  .concat(cur)
                  .filter(
                    ci =>
                      ci.dataType === this.queryFieldDataType.AdvancedOrderedNearTerm ||
                      ci.dataType === this.queryFieldDataType.AdvancedOrderedSimpleTerm
                  )
              : []
          )),
          []
        )
      );

    this.payload = this.node.categoryItem.payload as AdvancedOrderedGroupQueryItemDto;
    this.node.validationStatus = true;
  }
}
