import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { SplitConfig } from 'src/core/models/split/split-config.model';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { Observable, skip, Subscription, take } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import {
  ActiveListChange,
  ConversationScrollChange,
  DataChange,
  LayoutChange,
  SavePage,
} from 'src/core/actions/conversation/conversation-module.actions';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { GenericLookupTypeDto } from 'src/core/models/generic-lookup-type/generic-lookup-type.dto';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { ConversationListFilterPanelComponent } from '../conversation-list-filter-panel/conversation-list-filter-panel.component';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { ConversationLayoutService } from 'src/core/services/conversation/conversation-layout.service';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { ActivatedRoute, ActivationStart, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationsResultInputDto } from 'src/core/models/conversation/conversations-result-input.dto';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { CompactPlayerComponent } from 'src/ca-shared/player/compact-player/compact-player.component';

@Component({
  selector: 'ca-conversation-main',
  templateUrl: './conversation-main.component.html',
  styleUrls: ['./conversation-main.component.scss'],
})
export class ConversationMainComponent implements OnInit {
  localStorageName = 'conversation-list-layout-config';
  splitConfig: SplitConfig;
  conversationListTypes = ConversationListType;

  listVisibilities = {};
  conversationId: number;
  westMenuVisibility: boolean;
  lastScrollPosition = 0;
  layout: LayoutConfig;
  subscriptions: Subscription[] = [];
  isConversationResult: boolean = false;
  conversationResultNotFound: boolean = false;
  conversationResult: ConversationsResultInputDto;

  @Select(ConversationModuleState.getCurrentLayout)
  layout$: Observable<LayoutConfig>;

  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(GenericLookupTypeState.getGenericLookupTypes)
  genericLookupTypes$: Observable<GenericLookupTypeDto[]>;

  @Select(ConversationModuleState.getData)
  data$: Observable<ConversationListResponseDto>;

  @Select(ConversationModuleState.getLastScrollPosition)
  lastScrollPosition$: Observable<number>;

  @ViewChild('listContainer', {
    read: ViewContainerRef,
  })
  listContainer: ViewContainerRef;

  @ViewChild('compactPlayer', { static: false, read: CompactPlayerComponent })
  compactPlayer: CompactPlayerComponent;

  @ViewChild('filterPanel', { static: false, read: ConversationListFilterPanelComponent })
  filterPanel: ConversationListFilterPanelComponent;

  constructor(
    private store: Store,
    private conversationLayoutService: ConversationLayoutService,
    private route: ActivatedRoute,
    private conversationService: ConversationService,
    private router: Router
  ) {
    const resultId = this.route.snapshot.params.resultId;
    var queryParams = this.route.snapshot.queryParams;

    if (queryParams?.isTempQuery == 'true' && Number(queryParams.queryId) > 0) {
      this.conversationService.tempQueryId = Number(queryParams.queryId);
    }
    else{
      this.conversationService.tempQueryId = null;
    }

    if (resultId != null) {
      this.isConversationResult = true;
      this.setConversationResult(resultId);
    } else {
      this.conversationService.isDrillDownResult = false;
      this.conversationService.drillDownResultFilters = [];
    }

    conversationLayoutService.changeVisibilityForDetailPanel(false);
    this.splitConfig = {
      direction: 'horizontal',
      unit: 'pixel',
      useTransition: false,
      gutterSize: 4,
    };

    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        var queryParams = event.state.root.queryParams;

        if (queryParams?.isTempQuery == 'true' && Number(queryParams?.queryId) > 0) {
          this.setTempQuery(queryParams.queryId);
        }
        else{
          this.conversationService.tempQueryId = null;
        }
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activeList$.subscribe(activeList => {
        this.listVisibilities[ConversationListType.mixed] = false;
        this.listVisibilities[ConversationListType.call] = false;
        this.listVisibilities[ConversationListType.chat] = false;
        this.listVisibilities[ConversationListType.meeting] = false;
        this.listVisibilities[ConversationListType.videoCall] = false;
        this.listVisibilities[ConversationListType.email] = false;
        this.listVisibilities[activeList] = true;
      })
    );

    this.subscriptions.push(
      this.layout$.subscribe(layout => {
        this.westMenuVisibility = layout.west.visible;
        this.layout = layout;
      })
    );

    this.subscriptions.push(
      this.lastScrollPosition$.subscribe(lastScrollPosition => {
        this.lastScrollPosition = lastScrollPosition;
        this.restoreScrollPosition();
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe(val => {
        if (val instanceof NavigationStart && val.url !== '/conversation') {
          this.saveScrollPosition();
        }
      })
    );

    this.subscriptions.push(
      this.data$.subscribe(() => {
        if (this.compactPlayer) {
          this.compactPlayer.reset();
        }
      })
    );

    this.subscriptions.push(
      this.conversationService.filterRelatedGroupId.pipe(skip(1)).subscribe(groupId => {
        if (groupId && this.filterPanel && this.filterPanel.filterPanel) {
          var values = ObjectHelper.deepCopy(this.filterPanel.filterPanel.getStateObject());

          this.setRelatedGroupIdFilter(values, groupId);
          this.resetConversationTypeFilter(values);

          this.filterPanel.filterPanel.loadValues(values);
          this.filterPanel.filterPanel.saveState();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(substription => substription.unsubscribe());
  }

  changeVisibilityForFilterPanel(visible: boolean): void {
    this.conversationLayoutService.changeVisibilityForFilterPanel(visible);
  }

  changeVisibilityForDetailPanel(visible: boolean) {
    this.conversationLayoutService.changeVisibilityForDetailPanel(visible);
  }

  saveScrollPosition() {
    if (this.listContainer) {
      this.lastScrollPosition = this.listContainer.element.nativeElement.scrollTop;

      const scrollChangedAction = new ConversationScrollChange(this.lastScrollPosition);
      this.store.dispatch(scrollChangedAction);
    }
  }

  restoreScrollPosition() {
    if (this.listContainer) {
      const nativeEl = this.listContainer.element.nativeElement;

      nativeEl.scrollTop = this.lastScrollPosition;
    }
  }

  onDragEnd(e: { gutterNum: number; sizes: Array<number> }) {
    this.conversationLayoutService.onDragEnd(e);
  }

  resetLayout() {
    this.conversationLayoutService.resetLayout();
  }

  onScroll(ev) {
    this.saveScrollPosition();
  }

  onConversationIdChanged(conversation) {
    this.conversationId = conversation.id;
  }

  onClickHideButton() {
    this.changeVisibilityForFilterPanel(false);
  }
  onClickShowButton() {
    this.changeVisibilityForFilterPanel(true);
  }

  onFilterPanelCollapsed(eventArgs: { width: number }): void {
    this.layout.west.size = eventArgs.width;

    const action = new LayoutChange(this.layout);
    this.store.dispatch(action);
  }

  onFilterPanelExpanded(eventArgs): void {
    this.layout.west.size = LayoutConfig.DEFAULT.west.size;

    const action = new LayoutChange(this.layout);
    this.store.dispatch(action);
  }

  getActiveListType(types: number[]): number {
    if (types.length === 0 || types.length > 1) {
      return ConversationListType.mixed;
    } else {
      return types[0];
    }
  }

  private setTempQuery(queryId: number) {
    this.conversationService.tempQueryId = Number(queryId);
    this.conversationService.loadFromState();
  }

  private setConversationResult(id: string) {
    this.conversationService
      .getConversationResult(id)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.conversationResult = response;
          this.listVisibilities[ConversationListType.mixed] = false;
          this.listVisibilities[ConversationListType.call] = false;
          this.listVisibilities[ConversationListType.chat] = false;
          this.listVisibilities[ConversationListType.meeting] = false;
          this.listVisibilities[ConversationListType.videoCall] = false;
          this.listVisibilities[ConversationListType.email] = false;

          if (response) {
            var emptyList = new ConversationListResponseDto();
            emptyList.items = [];
            emptyList.totalCount = 0;
            const dataChangeAction = new DataChange(emptyList);
            this.store.dispatch(dataChangeAction);
            const savePageAction = new SavePage(0);
            this.store.dispatch(savePageAction);

            var input = response as ConversationsResultInputDto;
            var listType = input.filters.find(x => x.field == 'conversationListType');

            if (!listType) {
              listType = input.filters.find(x => x.field == 'conversationType');
              if (listType) {
                listType.field = 'conversationListType';
                listType.value = this.convertConversationTypeToConversationListType(listType.value);
              }
            }

            if (listType && listType.value) {
              var activeListType = this.getActiveListType(listType.value);
              this.listVisibilities[activeListType] = true;

              const actionActiveListChange = new ActiveListChange(activeListType);
              this.store.dispatch(actionActiveListChange);
            } else {
              this.listVisibilities[ConversationListType.mixed] = true;
            }

            this.conversationService.isDrillDownResult = true;
            this.conversationService.drillDownResultFilters = input.filters;

            this.conversationService.loadFromState();
          } else {
            this.conversationResultNotFound = true;
          }
        },
        error: () => {
          this.listVisibilities[ConversationListType.mixed] = false;
          this.listVisibilities[ConversationListType.call] = false;
          this.listVisibilities[ConversationListType.chat] = false;
          this.listVisibilities[ConversationListType.meeting] = false;
          this.listVisibilities[ConversationListType.videoCall] = false;
          this.listVisibilities[ConversationListType.email] = false;

          this.conversationResultNotFound = true;
        },
      });
  }

  private convertConversationTypeToConversationListType(
    type: ConversationType
  ): ConversationListType {
    if (type == ConversationType.all) {
      return [ConversationListType.mixed];
    } else if (type == ConversationType.call) {
      return [ConversationListType.call];
    } else if (type == ConversationType.chat) {
      return [ConversationListType.chat];
    } else if (type == ConversationType.meeting) {
      return [ConversationListType.meeting];
    } else if (type == ConversationType.videoCall) {
      return [ConversationListType.videoCall];
    } else if (type == ConversationType.email) {
      return [ConversationListType.email];
    }
  }

  private setRelatedGroupIdFilter(values: any, groupId: string): void {
    if (values.others) {
      if (values.others['relatedGroupId']) {
        values.others['relatedGroupId'].value = groupId;
      } else {
        values.others['relatedGroupId'] = {};
        values.others['relatedGroupId'].value = groupId;
        values.others['relatedGroupId'].extraData = {};
      }
    } else {
      values['others'] = {};
      values['others']['relatedGroupId'] = {};
      values['others']['relatedGroupId'].value = groupId;
      values['others']['relatedGroupId'].extraData = {};
    }
  }

  private resetConversationTypeFilter(values: any): void {
    if (values.conversationListType) {
      values.conversationListType.value = [];
    } else {
      values['conversationListType'] = {};
      values['conversationListType'].value = [];
      values['conversationListType'].extraData = {};
    }
  }
}
