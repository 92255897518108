import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-genesys-integration-settings',
  templateUrl: './genesys-integration-settings.component.html',
  styleUrls: ['./genesys-integration-settings.component.scss'],
})
export class GenesysIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'GenesysIntegration';
  private readonly clientId = `${this.prefix}.ClientId`;
  private readonly clientSecret = `${this.prefix}.ClientSecret`;
  private readonly region = `${this.prefix}.Region`;
  private readonly sourceName = `${this.prefix}.SourceName`;
  private readonly organizationId = `${this.prefix}.OrganizationId`;
  private readonly organizationName = `${this.prefix}.OrganizationName`;
  private readonly groupId = `${this.prefix}.GroupId`;
  private readonly groupName = `${this.prefix}.GroupName`;
  private readonly useQueueFilter = `${this.prefix}.UseQueueFilter`;
  private readonly queueFilterDictionary = `${this.prefix}.QueueFilterDictionary`;
  private readonly useLanguageDictionary = `${this.prefix}.UseLanguageDictionary`;
  private readonly languageDictionary = `${this.prefix}.LanguageDictionary`;
  private readonly chatIntegrationEnabled = `${this.prefix}.ChatIntegrationEnabled`;
  
  useQueueFilterCb = false;
  useLanguageDictionaryCb = false;
  chatIntegrationEnabledCb = false;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.chatIntegrationEnabledCb = config.getSetting(this.chatIntegrationEnabled) == 'true';
    this.useQueueFilterCb = config.getSetting(this.useQueueFilter) == 'true';
    this.useLanguageDictionaryCb = config.getSetting(this.useLanguageDictionary) == 'true';

    this.form = this.fb.group({
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      region: this.fb.group({
        name: this.region,
        value: [
          this.config.getSetting(this.region),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      sourceName: this.fb.group({
        name: this.sourceName,
        value: [
          this.config.getSetting(this.sourceName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      organizationId: this.fb.group({
        name: this.organizationId,
        value: [
          this.config.getSetting(this.organizationId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      organizationName: this.fb.group({
        name: this.organizationName,
        value: [
          this.config.getSetting(this.organizationName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      groupId: this.fb.group({
        name: this.groupId,
        value: [
          this.config.getSetting(this.groupId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      useQueueFilter: this.fb.group({
        name: this.useQueueFilter,
        value: [this.config.getSetting(this.useQueueFilter)],
      }),
      queueFilterDictionary: this.fb.group({
        name: this.queueFilterDictionary,
        value: [this.config.getSetting(this.queueFilterDictionary)],
      }),
      useLanguageDictionary: this.fb.group({
        name: this.useLanguageDictionary,
        value: [this.config.getSetting(this.useLanguageDictionary)],
      }),
      languageDictionary: this.fb.group({
        name: this.languageDictionary,
        value: [this.config.getSetting(this.languageDictionary)],
      }),
      chatIntegrationEnabled: this.fb.group({
        name: this.chatIntegrationEnabled,
        value: [this.config.getSetting(this.chatIntegrationEnabled)],
      }),
      groupName: this.fb.group({
        name: this.groupName,
        value: [
          this.config.getSetting(this.groupName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onUseQueueFilterChange(value: boolean) {
    if (!value) {
      this.form.controls.queueFilterDictionary.clearValidators();
    }
    this.form.controls.queueFilterDictionary.updateValueAndValidity();
  }

  onUseLanguageDictionaryChange(value: boolean) {
    if (!value) {
      this.form.controls.languageDictionary.clearValidators();
    }
    this.form.controls.languageDictionary.updateValueAndValidity();
  }

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('clientId').get('name').value,
      settingValue: this.form.get('clientId').get('value').value,
    });

    if (this.form.get('clientSecret').get('value').value) {
      settings.push({
        settingName: this.form.get('clientSecret').get('name').value,
        settingValue: this.form.get('clientSecret').get('value').value,
      });
    }

    settings.push({
      settingName: this.form.get('region').get('name').value,
      settingValue: this.form.get('region').get('value').value,
    });

    settings.push({
      settingName: this.form.get('sourceName').get('name').value,
      settingValue: this.form.get('sourceName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('organizationId').get('name').value,
      settingValue: this.form.get('organizationId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('organizationName').get('name').value,
      settingValue: this.form.get('organizationName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('groupId').get('name').value,
      settingValue: this.form.get('groupId').get('value').value,
    });

    settings.push({
      settingName: this.form.get('groupName').get('name').value,
      settingValue: this.form.get('groupName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useQueueFilter').get('name').value,
      settingValue: this.useQueueFilterCb.toString(),
    });

    settings.push({
      settingName: this.form.get('queueFilterDictionary').get('name').value,
      settingValue: this.form.get('queueFilterDictionary').get('value').value,
    });

    settings.push({
      settingName: this.form.get('useLanguageDictionary').get('name').value,
      settingValue: this.useLanguageDictionaryCb.toString(),
    });

    settings.push({
      settingName: this.form.get('languageDictionary').get('name').value,
      settingValue: this.form.get('languageDictionary').get('value').value,
    });
    

    settings.push({
      settingName: this.form.get('chatIntegrationEnabled').get('name').value,
      settingValue: this.chatIntegrationEnabledCb.toString(),
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SavedSuccessfully')
      );
    });
  }
}
