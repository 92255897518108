<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::GenesysIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::GenesysIntegrationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="region">
    <label for="region">{{ 'Settings::GenesysIntegrationRegion' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="region" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::GenesysIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="organizationId">
    <label for="organizationId">
      {{ 'Settings::GenesysIntegrationOrganizationId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="organizationId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="organizationName">
    <label for="organizationName">
      {{ 'Settings::GenesysIntegrationOrganizationName' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="organizationName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupId">
    <label for="groupId">{{ 'Settings::GenesysIntegrationGroupId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="groupId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupName">
    <label for="groupName">{{ 'Settings::GenesysIntegrationGroupName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="groupName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="useQueueFilter">
    <input
      type="checkbox"
      id="useQueueFilter"
      (change)="onUseQueueFilterChange($event.currentTarget.checked)"
      [(ngModel)]="useQueueFilterCb"
      formControlName="value"/>
      <label class="ps-1" for="useQueueFilter">
      {{ 'Settings::GenesysIntegrationUseQueueFilter' | abpLocalization }}
    </label>
  </div>
  <div class="form-group pt-2" formGroupName="queueFilterDictionary" *ngIf = "form.get('useQueueFilter.value').value">
    <label for="queueFilterDictionary">{{ 'Settings::GenesysIntegrationQueueFilterDictionary' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="queueFilterDictionary"
      placeholder='QueueID 1, QueueID 2, QueueID 3'
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="useLanguageDictionary">
    <input
      type="checkbox"
      id="useLanguageDictionary"
      (change)="onUseLanguageDictionaryChange($event.currentTarget.checked)"
      [(ngModel)]="useLanguageDictionaryCb"
      formControlName="value"/>
      <label class="ps-1" for="useLanguageDictionary">
      {{ 'Settings::GenesysIntegrationUseLanguageDictionary' | abpLocalization }}
    </label>
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'Settings::GenesysIntegrationUseLanguageDictionaryPopOver' | abpLocalization }}"
      container="body"></i>
  </div>
  <div class="form-group pt-2" formGroupName="languageDictionary" *ngIf = "form.get('useLanguageDictionary.value').value">
    <label for="languageDictionary">{{ 'Settings::GenesysIntegrationLanguageDictionary' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="languageDictionary"
      placeholder='{"genesysLanguageId1":"genesysEnglish","genesysLanguageId2":"genesysTurkish"}'
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="chatIntegrationEnabled">
    <input
      type="checkbox"
      id="chatIntegrationEnabled"
      [(ngModel)]="chatIntegrationEnabledCb"
      formControlName="value"/>
      <label class="ps-1" for="chatIntegrationEnabled">
      {{ 'Settings::GenesysIntegrationChatIntegrationEnabled' | abpLocalization }}
    </label>
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
