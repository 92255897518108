import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseSettingsDto } from 'src/core/models/license-setting/license-settings.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenseSettingsService {
  constructor(private http: HttpClient) {}

  saveHostSettings(settings: LicenseSettingsDto, tenantId: string) {
    const url = this.getApiUrl() + 'host-settings/' + tenantId;

    return this.http.put(url, settings) as Observable<number[]>;
  }

  saveSettings(settings: LicenseSettingsDto) {
    const url = this.getApiUrl();

    return this.http.put(url, settings) as Observable<number[]>;
  }

  loadSettings(tenantId: string) {
    const url = this.getApiUrl();

    let params = new HttpParams();
    params = params.append('tenantId', tenantId);

    return this.http.get(url, {
      params,
    }) as Observable<LicenseSettingsDto>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0
      ? apiBase + '/' + LicenseSettingsDto.apiUrl
      : LicenseSettingsDto.apiUrl;
  }
}
