import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import {
  LayoutChange,
  LayoutReset,
  ActiveListChange,
  ViewOptionsChange,
  ViewChange,
  DataRequest,
  DataChange,
  FilterChange,
  SorterChange,
  SpeedChange,
  VolumeChange,
  ListeningStatusChange,
  ConversationScrollChange,
  SavePage,
  CacheOptionChange,
  BeforeVolumeChange,
  CompactPlayerPositionChange,
} from 'src/core/actions/conversation/conversation-module.actions';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationMixedListViewOption } from 'src/core/models/generic-lookup-type/conversation/conversation-mixed-list-view-option.glt';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { ConversationCallListViewOption } from 'src/core/models/generic-lookup-type/conversation/conversation-call-list-view-option.glt';
import { ConversationChatListViewOption } from 'src/core/models/generic-lookup-type/conversation/conversation-chat-list-view-option.glt';
import { Injectable } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
import { Sort } from '@angular/material/sort';
const name = 'ConversationModuleState';
@State<ConversationModuleStateModel>({
  name: name,
  defaults: {
    activeList: null,
    activeView: null,
    viewOptions: [],
    currentPage: 0,
    lastScrollPosition: 0,
    dataRequested: null,
    filterFormValues: null,
    getDataFromCache: false,
    sortOptions: {},
    data: {
      totalCount: 0,
      items: [],
    },
    layout: {
      west: {
        size: 400,
        visible: true,
      },
      east: {
        size: 700,
        minSize: 650,
        maxSize: 800,
        visible: false,
      },
      center: {
        size: '*',
        visible: true,
      },
    },
    player: {
      speed: 1,
      volume: 100,
      beforeVolume: 100,
      conversationId: -1,
      position: 0,
    },
  } as ConversationModuleStateModel,
})
@Injectable()
export class ConversationModuleState extends GenericState<ConversationModuleState> {
  @Selector()
  static getSorterFormValues(state: ConversationModuleStateModel): Sort {
    return state.sortOptions[state.activeList] == null
      ? {
          active: 'StartTime',
          direction: 'desc',
        }
      : state.sortOptions[state.activeList];
  }

  @Selector()
  static getCurrentLayout(state: ConversationModuleStateModel): LayoutConfig {
    if (!state.layout.west.size) {
      state.layout.west.size = 350;
    }

    return state.layout;
  }

  @Selector()
  static getSortField(state: ConversationModuleStateModel): string {
    return state.sortOptions[state.activeList] == null
      ? 'StartTime'
      : state.sortOptions[state.activeList].active;
  }

  @Selector()
  static getSortDirection(state: ConversationModuleStateModel): 'asc' | 'desc' | '' {
    return state.sortOptions[state.activeList] == null
      ? 'desc'
      : state.sortOptions[state.activeList].direction;
  }

  @Selector()
  static getActiveList(state: ConversationModuleStateModel): number {
    return state.activeList || ConversationListType.mixed;
  }

  @Selector()
  static getActiveView(state: ConversationModuleStateModel): number {
    return state.activeView || ConversationMixedListViewOption.standard;
  }

  @Selector()
  static getFilter(state: ConversationModuleStateModel): any {
    return state.filterFormValues;
  }
  @Selector()
  static getViewOptions(state: ConversationModuleStateModel): GenericLookupDto[] {
    return state.viewOptions;
  }

  @Selector()
  static getData(state: ConversationModuleStateModel): ConversationListResponseDto {
    return state.data;
  }

  @Selector()
  static dataRequested(state: ConversationModuleStateModel): number | null {
    return state.dataRequested;
  }

  @Selector()
  static getCacheOption(state: ConversationModuleStateModel): boolean {
    return state.getDataFromCache;
  }

  @Selector()
  static getCurrentPage(state: ConversationModuleStateModel): number {
    return state.currentPage;
  }

  @Selector()
  static getLastScrollPosition(state: ConversationModuleStateModel): number {
    return state.lastScrollPosition;
  }

  @Action(LayoutChange)
  changeLayout(ctx: StateContext<ConversationModuleStateModel>, action: LayoutChange) {
    const currentState = ctx.getState();
    currentState.layout = Object.assign({}, action.payload);
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(LayoutReset)
  resetLayout(ctx: StateContext<ConversationModuleStateModel>, action: LayoutReset) {
    const currentState = ctx.getState();
    currentState.layout = {
      west: {
        size: 350,
        minSize: 250,
        maxSize: 450,
        visible: true,
      },
      east: {
        size: 700,
        minSize: 650,
        maxSize: 800,
        visible: false,
      },
      center: {
        size: '*',
        visible: true,
      },
    };

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(ActiveListChange)
  changeActiveList(ctx: StateContext<ConversationModuleStateModel>, action: ActiveListChange) {
    const currentState = ctx.getState();
    currentState.activeList = action.payload;
    currentState.data = {
      totalCount: 0,
      items: [],
    };

    if (action.payload === ConversationListType.mixed) {
      currentState.activeView = ConversationMixedListViewOption.standard;
    } else if (action.payload === ConversationListType.call) {
      currentState.activeView = ConversationCallListViewOption.standard;
    } else if (action.payload === ConversationListType.chat) {
      currentState.activeView = ConversationChatListViewOption.standard;
    }

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(ViewOptionsChange)
  changeViewOptions(ctx: StateContext<ConversationModuleStateModel>, action: ViewOptionsChange) {
    const currentState = ctx.getState();
    currentState.viewOptions = action.payload;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(ViewChange)
  changeView(ctx: StateContext<ConversationModuleStateModel>, action: ViewChange) {
    const currentState = ctx.getState();
    currentState.activeView = action.payload;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(DataRequest)
  requestData(ctx: StateContext<ConversationModuleStateModel>, action: DataRequest) {
    const currentState = ctx.getState();
    const pageSize = parseInt(this.config.getSetting('Conversation.ListPageSize'), 10);
    currentState.currentPage = action.pageIndex;
    currentState.dataRequested = new Date().getTime();
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(DataChange)
  changeData(ctx: StateContext<ConversationModuleStateModel>, action: DataChange) {
    const currentState = ctx.getState();
    currentState.data = action.payload;
    currentState.dataRequested = null;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(FilterChange)
  changeFilter(ctx: StateContext<ConversationModuleStateModel>, action: FilterChange) {
    const currentState = ctx.getState();
    currentState.filterFormValues = action.filterFormValues;
    currentState.dataRequested = new Date().getTime();
    currentState.stateModificationDate = new Date();
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(SorterChange)
  changeSorter(ctx: StateContext<ConversationModuleStateModel>, action: SorterChange) {
    const currentState = ctx.getState();

    currentState.sortOptions[currentState.activeList] = action.sort;
    currentState.dataRequested = new Date().getTime();
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(SpeedChange)
  changeSpeed(ctx: StateContext<ConversationModuleStateModel>, action: SpeedChange) {
    const currentState = ctx.getState();
    currentState.player.speed = action.speed;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(CompactPlayerPositionChange)
  changeCompactPlayerPosition(
    ctx: StateContext<ConversationModuleStateModel>,
    action: CompactPlayerPositionChange
  ) {
    const currentState = ctx.getState();
    currentState.player.conversationId = action.conversationId;
    currentState.player.position = action.position;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(CacheOptionChange)
  changeCacheOption(ctx: StateContext<ConversationModuleStateModel>, action: CacheOptionChange) {
    const currentState = ctx.getState();
    currentState.getDataFromCache = action.getCachedData;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(VolumeChange)
  changeVolume(ctx: StateContext<ConversationModuleStateModel>, action: VolumeChange) {
    const currentState = ctx.getState();
    currentState.player.volume = action.volume;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(BeforeVolumeChange)
  changeBeforeVolume(ctx: StateContext<ConversationModuleStateModel>, action: BeforeVolumeChange) {
    const currentState = ctx.getState();
    currentState.player.beforeVolume = action.beforeVolume;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(ListeningStatusChange)
  changeListeningStatus(
    ctx: StateContext<ConversationModuleStateModel>,
    action: ListeningStatusChange
  ) {
    const currentState = ctx.getState();
    const conversation = currentState.data.items.find(
      c => c.call != null && c.call.conversationId === action.conversationId
    );

    if (conversation) {
      conversation.listeningStatusId = action.listeningStatus;
    }
    ctx.patchState(currentState);
  }

  @Action(ConversationScrollChange)
  changeScrollPosition(
    ctx: StateContext<ConversationModuleStateModel>,
    action: ConversationScrollChange
  ) {
    const currentState = ctx.getState();
    currentState.lastScrollPosition = action.lastScrollPosition;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(SavePage)
  savePage(ctx: StateContext<ConversationModuleStateModel>, action: SavePage) {
    const currentState = ctx.getState();
    currentState.currentPage = action.pageIndex;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  constructor(private config: ConfigStateService, userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }
}
