import { FormControl } from '@angular/forms';

export class AttachedDataWildcardValidator {
  static wildcard(fc: FormControl) {
    if (!fc.value['filters'] || fc.value['filters'].length == 0) {
      return null;
    }

    let filters = fc.value['filters'];

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      if (
        filter.value.indexOf('*') >= 0 &&
        (!filter.value.endsWith('*') || filter.value.slice(0, -1).includes('*'))
      ) {
        return { wildcard: true };
      }
    }

    return null;
  }
}
