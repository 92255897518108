import {
  CreateErrorComponentService,
  DEFAULT_ERROR_LOCALIZATIONS,
  DEFAULT_ERROR_MESSAGES,
  ErrorScreenErrorCodes,
} from '@abp/ng.theme.shared';
import { AuthService, LocalizationService } from '@abp/ng.core';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate, RouterState } from '@ngxs/router-plugin';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private createErrorComponentService: CreateErrorComponentService,
    private authService: AuthService,
    private store: Store,
    private localizationService: LocalizationService
  ) {}

  showError(status: ErrorScreenErrorCodes) {
    const key = `defaultError${status}`;
    const title = {
      key: DEFAULT_ERROR_LOCALIZATIONS[key]?.title,
      defaultValue: DEFAULT_ERROR_MESSAGES[key]?.title,
    };
    const message = {
      key: DEFAULT_ERROR_LOCALIZATIONS[key]?.details,
      defaultValue: DEFAULT_ERROR_MESSAGES[key]?.details,
    };

    const instance = {
      title: title,
      details: message,
      status: status,
    };

    this.createErrorComponentService.execute(instance);
  }

  showSessionError(status: ErrorScreenErrorCodes) {
    const title = {
      key: '::SessionExpired',
      defaultValue: this.localizationService.instant('::SessionExpired'),
    };
    const message = {
      key: '::SessionExpiredMessage',
      defaultValue: this.localizationService.instant('::SessionExpiredMessage'),
    };

    const instance = {
      title: title,
      details: message,
      status: status,
      isHomeShow: false,
      hideCloseIcon: true,
    };

    this.createErrorComponentService.execute(instance);

    setTimeout(() => {
      this.authService.logout().subscribe(() => {
        this.store.dispatch(
          new Navigate(['/'], null, {
            state: { redirectUrl: this.store.selectSnapshot(RouterState).state.url },
          })
        );
      });
    }, 1500);
  }
}
