<div>
  <h2>{{ 'Settings::Licensing' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <div *ngIf="isSuperAdminUser">
    <div class="form-group pt-2">
      <label for="clientId">{{ 'Settings::License:ClientId' | abpLocalization }}</label>
      <input
        type="text"
        class="form-control"
        id="clientId"
        formControlName="clientId"
        [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization" />
    </div>
    <div class="form-group pt-2">
      <label for="clientSecret">
        {{ 'Settings::License:ClientSecret' | abpLocalization }}
      </label>
      <input
        type="text"
        class="form-control"
        id="clientSecret"
        formControlName="clientSecret"
        [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization" />
    </div>
    <div class="form-group pt-2">
      <label for="licenseUrl">
        {{ 'Settings::License:LicenseUrl' | abpLocalization }}
      </label>
      <input
        type="text"
        class="form-control"
        id="licenseUrl"
        formControlName="licenseURL"
        [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization" />
    </div>
  </div>
  <div class="form-group pt-2">
    <input type="checkbox" id="excludeImportAgent" formControlName="excludeImportAgent" />
    <label class="ps-1" for="excludeImportAgent">
      {{ 'Settings::Licensing.ExcludeImportAgent' | abpLocalization }}
    </label>
  </div>

  <button [disabled]="processing" type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>

<ng-template #licenseFeaturesModal let-modal>
  <div class="modal-body">
    <div class="form-panel-wrapper" *ngIf="featureList !== null">
      <h4>
        {{ 'Settings::License:LicensedFeatures' | abpLocalization }}
      </h4>
      <div class="row form-row m-0" *ngFor="let feature of featureList">
        {{ feature }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="close()" class="btn ca-btn ca-btn-neutral toolbar-button">
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>
