import { FormBuilder, FormControl } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { NumberEditorWithOperatorConfigurationModel } from './models/number-editor-with-operator-configuration.model';
import { NumberEditorWithOperatorValueModel } from './models/number-editor-with-operator-value.model';
import { Component, Input, OnInit } from '@angular/core';
import { FilterPanelEditorComponent } from '../../models/filter-panel-editor.component';

@Component({
  selector: 'number-editor-with-operator',
  templateUrl: './number-editor-with-operator.component.html',
  styleUrls: ['./number-editor-with-operator.component.scss'],
})
export class NumberEditorWithOperatorComponent
  extends FilterPanelEditorComponent
  implements OnInit {
  private _value: NumberEditorWithOperatorValueModel = {
    editorValue: 0,
    operator: 0,
  };
  private notations: string[] = ['=', '!=', '*', '=*', 'in', '>', '>=', '<', '<='];

  actualConfig: NumberEditorWithOperatorConfigurationModel;

  @Input()
  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as NumberEditorWithOperatorConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get showValidationMessage(): boolean {
    return (
      this.actualConfig.showValidationMessage != null && this.actualConfig.showValidationMessage
    );
  }

  init(): void {
    super.init();
  }

  reload(): void { }

  reset(): void { }

  onSelectChange(eventArgs) {
    if (!eventArgs && this.actualConfig.defaultOperator != null) {
      eventArgs = this.actualConfig.defaultOperator;
    }
    const val = eventArgs;

    this.value.operator = val;

    this.onChange();
  }

  onChangeValue(eventArgs) {
    if (this.actualConfig.nullable && !eventArgs?.toString().trim()) {
      return;
    }
    if (
      this.actualConfig.minValue != null &&
      (this.value.editorValue < this.actualConfig.minValue || !this.value.editorValue)
    ) {
      this.editorForm.patchValue({
        editorValue: this.actualConfig.minValue,
      });
    }

    if (this.actualConfig.maxValue != null && this.value.editorValue > this.actualConfig.maxValue) {
      this.editorForm.patchValue({
        editorValue: this.actualConfig.maxValue,
      });
    }
  }

  getNotation(operator) {
    return this.notations[operator];
  }

  get value(): NumberEditorWithOperatorValueModel {
    return this._value;
  }

  set value(value: NumberEditorWithOperatorValueModel) {
    this._value = value;

    if (this.value.operator == 0 && this.actualConfig?.defaultOperator) {
      this.value.operator = this.actualConfig?.defaultOperator;
    }

    this.editorForm.patchValue({
      editorValue: value.editorValue,
      operator: value.operator,
      editor: this.value,
    });
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.addControl('editorValue', new FormControl());
    this.editorForm.addControl('operator', new FormControl());

    this.editorForm.valueChanges.subscribe(val => {
      this._value.editorValue = val.editorValue;
      this._value.operator = val.operator;
      this.onChange();
    });
  }

  ngOnInit(): void { }

  autoSelectFirstItem(): void { }

  handleKeydown(ev: KeyboardEvent) {
    if (["e", "E"].includes(ev.key)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }
}
