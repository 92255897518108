<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('Conversation::QueryBuilder:Entity' | abpLocalization) + ')' }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ getOperatorLabel(payload.operator) | abpLocalization }}
        </label>
      </div>
      <div class="col-auto">
        <label>
          {{ 'Conversation::NamedEntity:' + getTemplateTooltip(payload.nerId) | abpLocalization }}
        </label>
      </div>
      <div
        *ngIf="currentEntityQueryType === EntityQueryType.Value"
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"{{ payload.term }}"</label>
      </div>
      <div class="col-auto" *ngIf="currentEntityQueryType === EntityQueryType.Interval">
        <label>{{ '::TimeRange' | abpLocalization }}:&nbsp;</label>
      </div>
      <div
        class="col-auto"
        *ngIf="currentEntityQueryType === EntityQueryType.Interval"
        container="body"
        placement="bottom"
        ngbTooltip=" {{
          currentEntityQueryIntervalType === EntityQueryIntervalType.Opening
            ? getOpeningIntervalTooltip()
            : getClosingIntervalTooltip()
        }}">
        <label *ngIf="currentEntityQueryIntervalType === EntityQueryIntervalType.Opening">
          {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
        </label>
        <label *ngIf="currentEntityQueryIntervalType === EntityQueryIntervalType.Closing">
          {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <div ngbDropdown class="d-inline-block">
          <button ngbDropdownToggle class="btn btn-sm ca-btn ca-btn-neutral-command">
            <i class="far fa-bars"></i>
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(EntityQueryType.Basic)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:Entity' | abpLocalization }}</span>
            </button>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(EntityQueryType.Value)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:EntityQueryWithValue' | abpLocalization }}</span>
            </button>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(EntityQueryType.Interval)">
              <i class="fas fa-circle small-icon"></i>
              <span>
                {{ 'Conversation::QueryBuilder:EntityQueryWithInterval' | abpLocalization }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <label>{{ 'Conversation::QueryBuilder:Entity' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.operator"
          formControlName="operator">
          <option *ngFor="let operator of operatorList" [value]="operator.notation">
            {{ getOperatorLabel(operator.notation) | abpLocalization }}
          </option>
        </select>
      </div>
      <div class="col-auto" *blockUI="blockUiNameTemplate; template: blockTemplate">
        <select
          class="form-select form-select-sm"
          (change)="templateSelectionChanged($event)"
          [(ngModel)]="payload.nerId"
          formControlName="nerId"
          style="min-width: 182px">
          <option *ngFor="let template of templateList" [value]="template.id">
            {{ 'Conversation::NamedEntity:' + template.labelClass | abpLocalization }}
          </option>
        </select>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="bottom"
        *ngIf="currentEntityQueryType === EntityQueryType.Value">
        <input
          type="text"
          [(ngModel)]="payload.term"
          class="form-control form-control-sm"
          formControlName="term" />
      </div>
      <div class="col-auto">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [allSide]="true"></ca-conversation-side-selector>
      </div>
      <div
        class="col-auto radio-container"
        container="body"
        placement="bottom"
        *ngIf="currentEntityQueryType === EntityQueryType.Interval">
        <div ngbTooltip="{{ getOpeningIntervalTooltip() }}" container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioOpening_' + payload.internalId"
            #radioOpening
            value="{{ EntityQueryIntervalType.Opening }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioOpening_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
          </label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getClosingIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioClosing_' + payload.internalId"
            #radioClosing
            value="{{ EntityQueryIntervalType.Closing }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioClosing_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
          </label>
        </div>
      </div>
      <div style="gap: 8px" class="col-auto d-flex flex-row justify-content-center">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickDuplicate($event)"
          ngbTooltip="{{ '::Duplicate' | abpLocalization }} (Shift + Enter)"
          container="body"
          placement="bottom">
          <i class="far fa-copy"></i>
        </button>
        <ca-query-builder-node-remove-button
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
