import { FeatureGuard } from './../core/guards/feature.guard';
import { authGuard, permissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnumResolver } from 'src/core/resolvers/enum.resolver';
import { GenericLookupTypeResolver } from 'src/core/resolvers/generic-lookup-type.resolver';
import { InitialSetupComponent } from './initial-setup/initial-setup/initial-setup.component';
import { UserGroupMainComponent } from './user-group/user-group/user-group-main/user-group-main.component';
import { NotFoundPageComponent } from 'src/ca-shared/not-found-page/not-found-page.component';
import { UserDetailMainComponent } from './replacement/user/user-detail/user-detail-main/user-detail-main.component';
import { CanDeactivateGuard } from 'src/core/guards/can-deactivate.guard';
import { SettingGuard } from 'src/core/guards/setting.guard';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { UserStateResolver } from 'src/core/resolvers/user-state.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'health',
    loadChildren: () => import('./health-check/health-check.module').then(m => m.HealthCheckModule),
  },
  {
    path: 'external',
    pathMatch: 'full',
    loadChildren: () => import('./external/external.module').then(m => m.ExternalModule),
  },
  {
    path: 'initial-setup',
    component: InitialSetupComponent,
    data: { title: 'Settings::InitialSetup.InitialSettings' },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./role-extended/role-extended.module').then(m => m.RoleExtendedModule),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./saas-extended/saas-extended.module').then(m => m.SaasExtendedModule),
  },
  {
    path: 'openiddict',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
    },
    loadChildren: () =>
      import('./applications-extended/applications-extended.module').then(
        m => m.ApplicationsExtendedModule
      ),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
    data: { title: 'AbpAuditLogging::AuditLogs' },
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
    data: { title: 'AbpSettingManagement::Settings' },
  },
  {
    path: 'analysis-report',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./analysis-report/analysis-report.module').then(m => m.AnalysisReportModule),
    data: { title: '::Menu:AnalyticsReports' },
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'conversation',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./conversation/conversation.module').then(m => m.ConversationModule),
    data: { title: 'Conversation::Conversations' },
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'conversation/v2',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./new-conversation/new-conversation.module').then(m => m.NewConversationModule),
    data: { title: 'Conversation::Conversations' },
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'categories',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
    data: { title: 'Category::Category' },
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'email-alert',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    data: {
      title: 'Report::EmailAlerts',
      forSettings: ['Abp.Identity.SignIn.RequireConfirmedEmail'],
    },
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [authGuard, permissionGuard, SettingGuard],
  },
  {
    path: 'analysis',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    canActivate: [authGuard, permissionGuard],
    loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule),
    data: { title: '::Analysis' },
  },
  {
    path: 'real-time',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    data: {
      forFeatures: ['RealTimeGroup.RealTime'],
      title: '::Menu:RTSA',
    },
    canActivate: [authGuard, permissionGuard, FeatureGuard],
    loadChildren: () => import('./real-time/real-time.module').then(m => m.RealTimeModule),
  },
  {
    path: 'identity/user-groups',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    canActivate: [authGuard],
    component: UserGroupMainComponent,
  },
  {
    path: 'identity/users',
    canActivate: [authGuard, permissionGuard],
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    children: [
      {
        path: ':id',
        component: UserDetailMainComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: 'training',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    data: {
      forFeatures: ['TrainingGroup.TrainingManagement'],
      title: 'Training::TrainingLists',
    },
    canActivate: [authGuard, permissionGuard, FeatureGuard],
    loadChildren: () => import('./training/training.module').then(m => m.TrainingModule),
  },
  {
    path: 'notifications',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    data: {
      title: 'Notification::Notifications',
    },
    canActivate: [authGuard, permissionGuard],
    loadChildren: () =>
      import('./notification/notification.module').then(m => m.NotificationModule),
  },
  {
    path: 'premium-reporting',
    loadChildren: () =>
      import('@sestek/premium-reporting').then(m => m.PremiumReportingModule.forLazy()),
  },
  {
    path: '',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    canActivate: [authGuard, permissionGuard],
    children: [],
    data: {
      routes: {
        path: '',
        order: 1,
        name: '::Menu:Analytics',
        iconClass: 'far fa-analytics',
        children: [
          {
            name: '::Menu:AnalyticsReports',
            order: 1,
            iconClass: 'far fa-area-chart',
            requiredPolicy: 'DashBoard.DashboardModule',
            path: 'analysis-report',
          },
          {
            name: 'Conversation::Conversations',
            order: 2,
            iconClass: 'far fa-comments',
            requiredPolicy: 'Conversation.ConversationManagement || AbpAccount.SettingManagement',
            path: 'conversation',
            data: { title: 'Conversation::Conversations' },
          },
          {
            name: 'Category::Categories',
            order: 3,
            iconClass: 'ca category',
            requiredPolicy: 'Query.QueryManagement || AbpAccount.SettingManagement',
            path: 'categories',
          },
          {
            name: '::Analysis',
            iconClass: 'far fa-chart-bar',
            path: 'analysis',
            order: 4,
            isLeaf: false,
            children: [
              {
                name: 'Analysis::StatisticsComparison',
                path: 'statistic-comparison',
                order: 1,
                iconClass: 'ca statistical-comparison-solid',
                requiredPolicy: 'Analysis.StatisticsComparison',
              },
              {
                name: 'Analysis::StatisticalAnalysis',
                path: 'statistic-analysis',
                order: 2,
                iconClass: 'far fa-chart-bar',
                requiredPolicy: 'Analysis.StatisticsComparison',
              },
              {
                name: 'Analysis::Trends',
                path: 'trend-analysis',
                order: 3,
                iconClass: 'far fa-chart-line',
                requiredPolicy: 'Analysis.Trends',
              },
              {
                name: 'Conversation::NonFcr:NonFcrCalls',
                path: 'non-fcr',
                order: 4,
                iconClass: 'far fa-file-alt',
                requiredPolicy: 'Analysis.NonFcr',
              },
              {
                name: 'Analysis::GenAI.Project.Title.AskGenAI',
                path: 'gen-ai-project',
                order: 5,
                iconClass: 'far fa-mind-share',
                requiredPolicy: 'Analysis.GenAIProject',
                forFeatures: [FeatureConstants.Analytics, FeatureConstants.AskGenAi],
              },
            ],
          },
          {
            name: 'Training::TrainingLists',
            order: 6,
            iconClass: 'far fa-comments',
            requiredPolicy: 'Training.TrainingManagement || AbpAccount.SettingManagement',
            path: 'training',
            forFeatures: ['TrainingGroup.TrainingManagement'],
          },
          {
            name: 'Report::EmailAlerts',
            order: 7,
            iconClass: 'far fa-envelope',
            path: 'email-alert',
            requiredPolicy: 'Report.EmailReport',
            forSettings: ['Abp.Identity.SignIn.RequireConfirmedEmail'],
          },
        ],
      },
    },
  },
  {
    path: 'quality',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () => import('./quality/quality.module').then(m => m.QualityModule),
    canActivate: [authGuard, FeatureGuard],
    data: {
      routes: {
        name: '::Menu:QualityManagement',
        order: 2,
        iconClass: 'far fa-badge-check',
        path: 'quality',
        forFeatures: ['QualityManagementGroup.QualityManagement'],
        requiredPolicy: 'QualityManagement.QualityManagement',
        children: [
          {
            name: 'Dashboard::Reports',
            order: 1,
            iconClass: 'far fa-area-chart',
            requiredPolicy: 'DashBoard.DashboardModule',
            path: 'quality-report',
            isLeaf: false,
            children: [
              {
                name: 'Dashboard::QualityReport:Agent',
                path: 'agent',
                order: 1,
                requiredPolicy: 'DashBoard.DashboardModule && DashBoard.AgentPerformance',
              },
              {
                name: 'Dashboard::QualityReport:Evaluator',
                path: 'evaluator',
                order: 2,
                requiredPolicy: 'DashBoard.DashboardModule && DashBoard.EvaluatorPerformance',
              },
            ],
          },
          {
            name: 'QualityManagement::Forms',
            order: 2,
            iconClass: 'far fa-file-check',
            requiredPolicy: 'QualityManagement.Form',
            path: 'form',
          },
          {
            name: 'QualityManagement::Assignments',
            order: 3,
            iconClass: 'ca assignment-solid',
            requiredPolicy: 'QualityManagement.Assignment',
            path: 'assignments',
          },
          {
            name: 'QualityManagement::MyTasks',
            order: 4,
            iconClass: 'far fa-tasks',
            requiredPolicy: 'QualityManagement.Task',
            path: 'my-tasks',
          },
          {
            name: 'QualityManagement::AutomaticAssignment',
            order: 5,
            iconClass: 'ca automatic-assignment-solid',
            requiredPolicy: 'QualityManagement.AutomaticAssignment',
            path: 'automatic-assignment',
          },
          {
            name: 'QualityManagement::EvaluationResult',
            order: 6,
            iconClass: 'far fa-poll-people',
            requiredPolicy: 'QualityManagement.EvaluationResult',
            path: 'evaluation-result',
          },
        ],
      },
    },
  },
  {
    path: 'realtime',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () => import('./real-time/real-time.module').then(m => m.RealTimeModule),
    canActivate: [authGuard, FeatureGuard],
    data: {
      routes: {
        name: '::Menu:RTSA',
        order: 3,
        iconClass: 'far fa-stopwatch',
        path: 'real-time',
        forFeatures: ['RealTimeGroup.RealTime'],
        isLeaf: false,
        children: [
          {
            name: 'RealTime::Dashboard',
            path: 'dashboard',
            order: 1,
            iconClass: 'far fa-chart-user',
            requiredPolicy: 'RealTime.CanViewSupervisorDashboard',
          },
          {
            name: 'RealTime::Scenarios',
            path: 'scenario',
            order: 2,
            iconClass: 'far fa-list',
            requiredPolicy: 'RealTime.CanManageScenario',
          },
          {
            name: 'RealTime::Notifications',
            path: 'moments',
            order: 3,
            iconClass: 'far fa-comment-alt',
            requiredPolicy: 'RealTime.RealTimeModule',
          },
          {
            name: 'RealTime::Reports',
            path: 'reports',
            order: 4,
            iconClass: 'far fa-area-chart',
            requiredPolicy: 'RealTime.RealTimeModule',
          },
        ],
      },
    },
  },
  {
    path: 'administration',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () =>
      import('./administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'fennec',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () => import('./fennec/fennec.module').then(m => m.FennecModule),
    canActivate: [authGuard, FeatureGuard],
    data: {
      routes: {
        name: '::Menu:Fennec',
        order: 4,
        iconClass: 'far fa-microphone-stand',
        path: 'fennec',
        forFeatures: ['FennecGroup.Fennec'],
        children: [
          {
            name: 'Fennec::FennecDevices',
            order: 1,
            iconClass: 'far fa-server',
            path: 'fennec-device',
          },
        ],
      },
    },
  },
  {
    path: 'playground',
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
      state: UserStateResolver,
    },
    loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundModule),
    canActivate: [authGuard, FeatureGuard],
    data: {
      routes: {
        name: '::Playground',
        iconClass: 'far fa-cubes',
        path: 'playground',
        forFeatures: ['PlaygroundFeatureGroup.Playground'],
        children: [
          {
            name: 'Trees',
            path: 'trees',
            order: 10,
            iconClass: 'fa-regular fa-list-tree',
            children: [
              {
                name: 'Department',
                path: 'department',
                order: 0,
                iconClass: 'fa-regular fa-buildings',
              },
            ],
          },
          {
            name: 'Selectors',
            path: 'selectors',
            order: 20,
            iconClass: 'fa-duotone fa-list-check',
            children: [
              {
                name: 'User',
                path: 'user',
                order: 0,
                iconClass: 'fa-duotone fa-users',
              },
            ],
          },
          {
            name: 'Selector List',
            path: 'selectors',
            order: 30,
            iconClass: 'far fa-project-diagram',
          },
          {
            name: '::DropdownTree',
            path: 'dropdown-tree',
            order: 40,
            iconClass: 'far fa-project-diagram',
          },
          {
            name: '::Player',
            path: 'player',
            order: 50,
            iconClass: 'far fa-play',
          },
          {
            name: '::DateRangePicker',
            path: 'datetme-range-picker-example',
            order: 60,
            iconClass: 'far fa-calendar',
          },
          {
            name: '::UserPhoto',
            path: 'user-photo',
            order: 70,
            iconClass: 'far fa-user',
          },
          {
            name: '::ReuseRouteExample',
            path: 'routing',
            order: 80,
            iconClass: 'far fa-random',
          },
          {
            name: 'Ng wizard',
            path: 'ng-wizard-example',
            order: 90,
            iconClass: 'far fa-hat-wizard',
          },
          {
            name: 'Signalr',
            path: 'signalr-example',
            order: 100,
            iconClass: 'far fa-exchange-alt',
          },
          {
            name: 'Charts',
            path: 'chart-sample',
            order: 110,
            iconClass: 'far fa-chart-line',
          },
          {
            name: 'Word Cloud',
            path: 'word-cloud',
            order: 120,
            iconClass: 'far fa-cloud',
          },
          {
            name: 'Filter Panel',
            path: 'filter-panel',
            order: 130,
            iconClass: 'far fa-filter',
          },
          {
            name: 'Quality',
            path: 'quality-form-question',
            order: 140,
            iconClass: 'far fa-question',
          },

          {
            name: 'Drag & Drop',
            path: 'drag-drop-example',
            order: 150,
            iconClass: 'far fa-grip-vertical',
          },
          {
            name: 'Agent Auto Complete Example',
            path: 'agent-auto-complete-example',
            order: 160,
            iconClass: 'far fa-project-diagram',
          },
          {
            name: 'Department Tree Example',
            path: 'department-tree-example',
            order: 170,
            iconClass: 'far fa-project-diagram',
          },
          {
            name: 'Notifications',
            path: 'notification',
            order: 180,
            iconClass: 'far fa-bell',
          },
        ],
      },
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
