import { Injectable } from '@angular/core';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';

@Injectable({
  providedIn: 'root',
})
export class TempQueryHelper {
  constructor(private operators: Operators) {

  }

  setTempQueryFilter(filters: FilterItemDto[], tempQueryId: number) {
    const currentQuery = filters.find(x => x.field == 'query');

    if (currentQuery && Array.isArray(currentQuery.value)) {
      if (!currentQuery.value.includes(tempQueryId)) {
        currentQuery.value.push(tempQueryId);
      }
    } else {
      filters.push({
        field: 'query',
        operator: this.operators.Contains,
        value: [tempQueryId],
      });
    }

    const qS = filters.find(x => x.field == 'quickSearchTerm')?.value;

    if (
      qS &&
      (qS.indexOf('" near "') >= 0 ||
        qS.indexOf('not "') >= 0 ||
        qS.indexOf('cu:') >= 0 ||
        qS.indexOf('ag:') >= 0)
    ) {
      filters = filters.filter(x => x.field != 'quickSearchTerm');
    }

    return filters;
  }
}
