import { Injectable, Injector } from '@angular/core';
import { ServerSideEnum } from './server-side-enum.model';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('QueryTemporaryType')
export class QueryTemporaryType extends ServerSideEnum {
  Conversation: number;
  DynamicReport: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
