import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Operators } from 'src/core/models/request/operator.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { AdvancedOrderedNearTermQueryItemDto } from 'src/core/models/query/advanced-ordered-near-term-query-item.dto';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { RegexTermValidator } from 'src/core/validators/query/regex-term.validator';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { map, Observable } from 'rxjs';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { Store } from '@ngxs/store';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';

@Component({
  selector: 'ca-query-tree-node-advanced-ordered-near-term',
  templateUrl: './query-tree-node-advanced-ordered-near-term.component.html',
  styleUrls: ['./query-tree-node-advanced-ordered-near-term.component.scss'],
})
export class QueryTreeNodeAdvancedOrderedNearTermComponent
  extends QueryEditorBase
  implements OnInit
{
  payload: AdvancedOrderedNearTermQueryItemDto;
  conversationSides$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;

  private _minDistance: number = 1;
  private _maxDistance: number = 999;

  get minDistance(): number {
    return this._minDistance;
  }

  get maxDistance(): number {
    return this._maxDistance;
  }

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private genericLookupService: GenericLookupTypeService,
    public operators: Operators,
    private queryFieldDataType: QueryFieldDataType
  ) {
    super();

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.queryForm = this.fb.group(
      {
        firstTerm: [
          null,
          {
            validators: [Validators.required, RegexTermValidator.Validator],
          },
        ],
        secondTerm: [
          null,
          {
            validators: [Validators.required, RegexTermValidator.Validator],
          },
        ],
        maximumDistance: [
          null,
          {
            validators: [
              Validators.required,
              IntegerValidator.minMax(this.minDistance, this.maxDistance),
            ],
          },
        ],
        maximumDistanceToNextItem: [
          null,
          {
            validators: [
              Validators.required,
              IntegerValidator.minMax(this.minDistance, this.maxDistance),
            ],
          },
        ],
        conversationSide: [null],
        not: [null],
        searchInOrder: [null],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });
  }

  get isLastEditor(): boolean {
    if (this.node.parentNode.children) {
      var advancedOrderedSimpleTerms = this.node.parentNode.children.filter(
        c =>
          c.categoryItem.dataType === this.queryFieldDataType.AdvancedOrderedNearTerm ||
          c.categoryItem.dataType === this.queryFieldDataType.AdvancedOrderedSimpleTerm
      );

      const indexOfCurrent = advancedOrderedSimpleTerms.findIndex(
        o => o.categoryItem.payload.internalId === this.payload.internalId
      );
      return indexOfCurrent === advancedOrderedSimpleTerms.length - 1;
    }

    return false;
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as unknown as AdvancedOrderedNearTermQueryItemDto;

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      const conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
  }
}
