import { LocalizationService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor(private localizationService: LocalizationService) {
    super();
  }
  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 2147483647) {
      return (
        `${page * pageSize + 1} - ${Math.min((page + 1) * pageSize, length)}` +
        ' / ' +
        this.localizationService.instant('Conversation::SeeTotal')
      );
    } else {
      return `${page * pageSize + 1} - ${Math.min((page + 1) * pageSize, length)} / ${length}`;
    }
  };
}
