<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto" *ngIf="payload.not && isLastEditor">
        <label style="font-style: italic">{{ 'Query::NOT' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <label>{{ '(' + ('Query::NearQueryShortText' | abpLocalization) + ')' }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::FirstTerm' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"</label>
        <label>{{ payload.firstTerm }}</label>
        <label>"</label>
      </div>
      <!-- <div
        class="col-auto"
        ngbTooltip="{{ 'Query::MaximumDistance' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>&nbsp;</label>
        <label>-</label>
        <label>{{ payload.maximumDistance }}</label>
        <label>-</label>
      </div> -->
      <div
        class="col-auto distance"
        ngbTooltip="{{ 'Query::MaximumDistance' | abpLocalization }}"
        container="body"
        placement="left auto">
        <i class="fas fa-arrow-left"></i>
        <span class="number">{{ payload.maximumDistance }}</span>
        <i class="fas fa-arrow-right"></i>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::SecondTerm' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"</label>
        <label>{{ payload.secondTerm }}</label>
        <label>"</label>
      </div>
      <div
        class="col-auto"
        *ngIf="!isLastEditor"
        ngbTooltip="{{ 'Query::MaximumDistanceToNextItem' | abpLocalization }}"
        container="body"
        placement="left auto">
        <i class="fas fa-level-down"></i>
        <label>{{ payload.maximumDistanceToNextItem }}</label>
      </div>

      <div class="col-auto">
        <label *ngIf="payload.searchInOrder" style="font-style: italic">
          {{ '::SearchinOrder' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-9 row align-items-center full-height">
        <div class="col-auto">
          <label>{{ 'Query::NearQueryShortText' | abpLocalization }}</label>
        </div>
        <div class="col-auto" *ngIf="isLastEditor">
          <mat-slide-toggle [(ngModel)]="payload.not" formControlName="not">
            {{ 'Query::NOT' | abpLocalization }}
          </mat-slide-toggle>
        </div>
        <div class="col-auto">
          <mat-slide-toggle [(ngModel)]="payload.searchInOrder" formControlName="searchInOrder">
            {{ '::SearchinOrder' | abpLocalization }}
          </mat-slide-toggle>
        </div>
        <div
          class="col"
          ngbTooltip="{{ '::FirstTerm' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="text"
            [(ngModel)]="payload.firstTerm"
            class="form-control form-control-sm"
            formControlName="firstTerm" />
        </div>
        <div
          class="col"
          ngbTooltip="{{ '::SecondTerm' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="text"
            [(ngModel)]="payload.secondTerm"
            class="form-control form-control-sm"
            formControlName="secondTerm" />
        </div>
        <div
          class="col-auto"
          ngbTooltip="{{ 'Query::MaximumDistance' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="number"
            [min]="minDistance"
            [max]="maxDistance"
            style="width: 75px"
            class="form-control form-control-sm"
            formControlName="maximumDistance"
            [(ngModel)]="payload.maximumDistance" />
        </div>
        <div class="col-auto"></div>
      </div>
      <div class="col-3 row align-items-center full-height item-divider">
        <div
          [hidden]="isLastEditor"
          class="col-auto"
          ngbTooltip="{{ 'Query::MaximumDistanceToNextItem' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="number"
            [min]="minDistance"
            [max]="maxDistance"
            style="width: 75px"
            class="form-control form-control-sm"
            formControlName="maximumDistanceToNextItem"
            [(ngModel)]="payload.maximumDistanceToNextItem" />
        </div>
        <div class="col-auto">
          <div class="col-auto">
            <ca-conversation-side-selector
              formControlName="conversationSide"
              [(ngModel)]="payload.sideId"></ca-conversation-side-selector>
          </div>
        </div>
        <ca-query-builder-node-remove-button
          *ngIf="!isFirstTwoEditor"
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
