<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Conversation::QueryBuilder:NegativeContent' | abpLocalization }}
        </label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ getOperatorLabel(payload.operator) | abpLocalization }}
        </label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Channel' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label *ngIf="payload.sideId !== conversationMarkChannels.undefined">
          {{ 'GenericLookup::' + currentConversationMarkChannel | abpLocalization }}
        </label>
        <label *ngIf="payload.sideId === conversationMarkChannels.undefined">
          {{ 'GenericLookup::Any' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Conversation::QueryBuilder:NegativeContent' | abpLocalization }}
        </label>
      </div>
      <div class="col-auto">
        <mat-select
          class="form-control form-control-sm"
          hideSingleSelectionIndicator="true"
          [(value)]="payload.operator"
          panelClass="mat-select-position width-max-content"
          disableOptionCentering>
          <mat-option *ngFor="let operator of operatorList" [value]="operator.notation">
            <div class="mat-option-container">
              <div class="icon-container" title="{{ operator.label | abpLocalization }}">
                <span
                  *ngIf="operator.icon !== null"
                  [class]="operator.icon"
                  style="margin-right: 5px"></span>
                <span>{{ operator.label | abpLocalization }}</span>
              </div>
            </div>
          </mat-option>
          <mat-select-trigger>
            <div class="mat-option-container">
              <div
                class="icon-container"
                title="{{ getOperatorLabel(payload.operator) | abpLocalization }}">
                <span
                  *ngIf="getOperatorIcon(payload.operator) !== null"
                  [class]="getOperatorIcon(payload.operator)"
                  style="margin-right: 5px"></span>
                <span>{{ getOperatorLabel(payload.operator) | abpLocalization }}</span>
              </div>
            </div>
          </mat-select-trigger>
        </mat-select>
      </div>
      <div class="col-auto">
        <ca-conversation-mark-channel-selector
          id="querySelector"
          [(ngModel)]="payload.sideId"
          (ngModelChange)="valueChanged()"></ca-conversation-mark-channel-selector>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
