<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent [formGroup]="queryForm">
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Query::AdvancedOrderedQuery' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Query::AdvancedOrderedQuery' | abpLocalization }}
        </label>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      <div class="col-auto">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          [matMenuTriggerFor]="root"
          (menuOpened)="onMenuOpened()"
          (menuClosed)="onMenuClosed()"
          ngbTooltip="{{ '::AddNewNode' | abpLocalization }}"
          placement="bottom">
          <i class="fad fa-layer-plus"></i>
        </button>

        <mat-menu #root="matMenu">
          <ng-container *ngFor="let categoryItem of categoryItems">
            <button
              class="tree-node-menu-button mt-1 mb-1"
              mat-menu-item
              (click)="onAddNodeClick(category, categoryItem)">
              <div class="query left-bordered ps-2 text-search">
                {{ 'Conversation::' + categoryItem.title | abpLocalization }}
              </div>
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</ng-template>
